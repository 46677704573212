import React from "react";
export default ({ event }) => (
    <button
        type="button"
        className="botao-excluir"
        style={{
            width: 20,
            height: 20,
            padding: "0 3px",
            border: "1px solid #222",
            outline: "none",
            borderRadius: "0.3rem",
        }}
        onClick={event}
    >
        <i className="fas fa-times" />
    </button>
);
