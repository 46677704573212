import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import Select from "../common/form/select";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputMask from "../common/form/labelAndInputMask";

import { setModoTela, initForm } from "./concessionariaActions";

class ConcessionariaForm extends Component {
	render() {
		let readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";

		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Grid cols="12 12 6 6">
								<ContentCardBody>
									<Row>
										  <Field name="nome" component={LabelAndInput} label="Nome" placeholder="Informe o nome" cols="12 12 12 12" readOnly={readOnly} />
									</Row>
									<Row>
										<Select
											cols="12 12 12 12"
											name="id_estado"
											options={this.props.listaEstado}
											label="UF"
											placeholder="Selecione a UF"
											value={this.props.formularioValues.id_estado}
											onChange={(value) => {
												this.props.initForm({
													...this.props.formularioValues,
													id_estado: value,
												});
											}}
											readOnly={readOnly}
										/>
									</Row>
									<Row>
										  <Field name="cnpj" component={LabelAndInputMask} label="CNPJ" placeholder="Informe o CNPJ" cols="12 12 12 12" mask="99.999.999/9999-99" readOnly={readOnly} />
									</Row>
								</ContentCardBody>
							</Grid>
						</Row>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols="6 6 4 3">
									{this.props.excluir ? (
										<Button
											text="Excluir"
											submit
											type={"danger"}
											icon={"fa fa-trash"}
											disabled={!this.props.usuarioLogado.acesso_concessionaria_excluir}
										/>
									) : (
										<Button
											text={"Salvar"}
											submit
											type={"success"}
											icon={"fa fa-check"}
											disabled={
												!(this.props.usuarioLogado.acesso_concessionaria_adicionar || this.props.usuarioLogado.acesso_concessionaria_editar) ||
												this.props.visualizar
											}
										/>
									)}
								</Grid>
								<Grid cols="6 6 4 3">
									<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</>
		);
	}
}

ConcessionariaForm = reduxForm({
	form: "concessionariaForm",
	destroyOnUnmount: false,
})(ConcessionariaForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("concessionariaForm")(state),
	registro: state.concessionaria.registro,
	lista: state.concessionaria.lista,
	listaEstado: state.concessionaria.listaEstado,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(ConcessionariaForm);
