import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardBody from "../../common/template/contentCardBody";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";

import FormatUtils from "../../common/formatUtils/FormatUtils";
import {
    getListaGraficoGeracaoQuantidadeAnual,
    getGeracaoCadastro,
    getListaGraficoGeracaoSaldo,
    getListaGraficoGeracaoAvaliacaoProducao,
    getListaGraficoGeracaoComparativoConsumo,
    getListaGraficoGeracaoEconomia,
    getListaGraficoGeracaoEconomiaPonta,
    getListaGraficoGeracaoConsumoInstantaneo,
    getDemanda,
    setFiltro,
} from "../analiseActions";

import { adicionarPontosMilhar } from "../../utils/adicionarPontosMilhar.js";
import { montarGraficoLineMensal } from "../../common/graficos/montarGraficoLineMensal.js";
import { montarGraficoClusteredColumnAnual } from "../../common/graficos/montarGraficoClusteredColumnAnual.js";
import { montarGraficoClusteredColumnMensal } from "../../common/graficos/montarGraficoClusteredColumnMensal.js";
import { montarGraficoClusteredColumnLineMensal } from "../../common/graficos/montarGraficoClusteredColumnLineMensal.js";

function analiseMicroMiniGeracao(props) {
    const {
        state,
        buscarPropriedadeUcFiltrada,
        contratoDemanda,
        listaGraficoGeracaoQuantidadeAnual,
        listaGraficoGeracaoSaldo,
        listaGraficoGeracaoAvaliacaoProducaoAnual,
        listaGraficoGeracaoComparativoConsumo,
        listaGraficoGeracaoEconomia,
        listaGraficoGeracaoEconomiaPonta,
        listaGraficoGeracaoConsumoInstantaneo,
        listaGeracaoContrato,
        getListaGraficoGeracaoSaldo,
        getListaGraficoGeracaoQuantidadeAnual,
        getGeracaoCadastro,
        getListaGraficoGeracaoAvaliacaoProducao,
        getListaGraficoGeracaoComparativoConsumo,
        getListaGraficoGeracaoEconomia,
        getListaGraficoGeracaoEconomiaPonta,
        getListaGraficoGeracaoConsumoInstantaneo,
        getDemanda,
        setAguardando,
    } = props;

    const [graficoGeracaoQuantidadeAnual, setGraficoGeracaoQuantidadeAnual] = useState(null);
    const [graficoGeracaoQuantidadeMensal, setGraficoGeracaoQuantidadeMensal] = useState(null);
    const [graficoGeracaoSaldo, setGraficoGeracaoSaldo] = useState(null);
    const [graficoGeracaoAvaliacaoProducaoAnual, setGraficoGeracaoAvaliacaoProducaoAnual] = useState(null);
    const [graficoGeracaoAvaliacaoProducaoMensal, setGraficoGeracaoAvaliacaoProducaoMensal] = useState(null);
    const [graficoGeracaoComparativoConsumo, setGraficoGeracaoComparativoConsumo] = useState(null);
    const [graficoGeracaoEconomia, setGraficoGeracaoEconomia] = useState(null);
    const [graficoGeracaoRetornoFinanceiro, setGraficoGeracaoRetornoFinanceiro] = useState(null);
    const [graficoGeracaoEconomiaTotal, setGraficoGeracaoEconomiaTotal] = useState(null);
    const [graficoGeracaoAnaliseRetornoFinanceiroAnual, setGraficoGeracaoAnaliseRetornoFinanceiroAnual] = useState(null);
    const [graficoGeracaoEconomiaPonta, setGraficoGeracaoEconomiaPonta] = useState(null);
    const [graficoGeracaoConsumoInstantaneo, setGraficoGeracaoConsumoInstantaneo] = useState(null);

    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([
                getGeracaoCadastro(),
                getListaGraficoGeracaoQuantidadeAnual(),
                getListaGraficoGeracaoSaldo(),
                getListaGraficoGeracaoAvaliacaoProducao(),
                getListaGraficoGeracaoComparativoConsumo(),
                getListaGraficoGeracaoEconomia(),
                getListaGraficoGeracaoEconomiaPonta(),
                getListaGraficoGeracaoConsumoInstantaneo(),
                getDemanda(),
            ]);

            setAguardando(false);
        };

        buscarDados();
        if (!state.aguardando) {
            atualizarTodosGraficos();
        }
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoGeracaoQuantidadeAnual();
        atualizarListaGraficoGeracaoSaldo();
        atualizarListaGraficoGeracaoAvaliacaoProducao();
        atualizarListaGraficoGeracaoComparativoConsumo();
        atualizarListaGraficoGeracaoEconomia();
        atualizarListaGraficoGeracaoEconomiaPonta();
        atualizarListaGraficoGeracaoConsumoInstantaneo();
    }
    function desmontarTodosGraficos() {
        if (graficoGeracaoQuantidadeAnual) graficoGeracaoQuantidadeAnual.dispose();
        if (graficoGeracaoQuantidadeMensal) graficoGeracaoQuantidadeMensal.dispose();
        if (graficoGeracaoSaldo) graficoGeracaoSaldo.dispose();
        if (graficoGeracaoAvaliacaoProducaoAnual) graficoGeracaoAvaliacaoProducaoAnual.dispose();
        if (graficoGeracaoComparativoConsumo) graficoGeracaoComparativoConsumo.dispose();
        if (graficoGeracaoEconomia) graficoGeracaoEconomia.dispose();
        if (graficoGeracaoRetornoFinanceiro) graficoGeracaoRetornoFinanceiro.dispose();
        if (graficoGeracaoEconomiaTotal) graficoGeracaoEconomiaTotal.dispose();
        if (graficoGeracaoAnaliseRetornoFinanceiroAnual) graficoGeracaoAnaliseRetornoFinanceiroAnual.dispose();
        if (graficoGeracaoEconomiaPonta) graficoGeracaoEconomiaPonta.dispose();
        if (graficoGeracaoConsumoInstantaneo) graficoGeracaoConsumoInstantaneo.dispose();
    }

    function atualizarListaGraficoGeracaoQuantidadeAnual() {
        const graficoGeracaoQuantidadeAnualAtualizado = montarGraficoClusteredColumnAnual(
            state,
            graficoGeracaoQuantidadeAnual,
            "graficoGeracaoQuantidadeAnual",
            listaGraficoGeracaoQuantidadeAnual,
            "Comportamento de Energia Excedente Anual (kWh)",
            "",
            "kWh"
        );
        setGraficoGeracaoQuantidadeAnual(graficoGeracaoQuantidadeAnualAtualizado);

        const graficoGeracaoQuantidadeMensalAtualizado = montarGraficoLineMensal(
            state,
            graficoGeracaoQuantidadeMensal,
            "graficoGeracaoQuantidadeMensal",
            listaGraficoGeracaoQuantidadeAnual,
            "Comportamento de Energia Excedente Mensal (kWh)",
            "",
            "kWh"
        );
        setGraficoGeracaoQuantidadeMensal(graficoGeracaoQuantidadeMensalAtualizado);
    }

    function atualizarListaGraficoGeracaoSaldo() {
        const graficoGeracaoSaldoAtualizado = montarGraficoClusteredColumnMensal(state, graficoGeracaoSaldo, "graficoGeracaoSaldo", listaGraficoGeracaoSaldo, "Saldo de Geração (kWh)", "", "kWh");
        setGraficoGeracaoSaldo(graficoGeracaoSaldoAtualizado);
    }

    function atualizarListaGraficoGeracaoAvaliacaoProducao() {
        const graficoGeracaoAvaliacaoProducaoAnualAtualizado = montarGraficoClusteredColumnAnual(
            state,
            graficoGeracaoAvaliacaoProducaoAnual,
            "graficoGeracaoAvaliacaoProducaoAnual",
            listaGraficoGeracaoAvaliacaoProducaoAnual.map((item) => ({
                ...item,
                anos: item.anos.filter((itemAno) => !itemAno.ano.includes("indice")),
            })),
            "Avaliação da Produção Total (kWh)",
            "",
            "kWh"
        );
        setGraficoGeracaoAvaliacaoProducaoAnual(graficoGeracaoAvaliacaoProducaoAnualAtualizado);

        const graficoGeracaoAvaliacaoProducaoMensalAtualizado = montarGraficoClusteredColumnLineMensal(
            state,
            graficoGeracaoAvaliacaoProducaoMensal,
            "graficoGeracaoAvaliacaoProducaoMensal",
            listaGraficoGeracaoAvaliacaoProducaoAnual.map((item) => ({
                ...item,
                anos: item.anos.filter((itemAno) => !itemAno.ano.includes("indice")),
            })),
            "",
            "",
            "kWh"
        );
        setGraficoGeracaoAvaliacaoProducaoMensal(graficoGeracaoAvaliacaoProducaoMensalAtualizado);
    }

    function atualizarListaGraficoGeracaoComparativoConsumo() {
        const graficoGeracaoComparativoConsumoAtualizado = montarGraficoClusteredColumnMensal(
            state,
            graficoGeracaoComparativoConsumo,
            "graficoGeracaoComparativoConsumo",
            listaGraficoGeracaoComparativoConsumo.map((item) => ({
                mes: item.mes,
                anos: item.tipos.map((tipo) => ({
                    ano: tipo.tipo,
                    valor: tipo.tipo === "Antes" ? (item.tipos[1] && item.tipos[1].valor > 0 ? tipo.valor : 0) : tipo.valor,
                })),
            })),
            "",
            "kWh",
            ""
        );
        setGraficoGeracaoComparativoConsumo(graficoGeracaoComparativoConsumoAtualizado);
    }

    function atualizarListaGraficoGeracaoEconomia() {
        const graficoGeracaoEconomiaAtualizado = montarGraficoClusteredColumnMensal(state, graficoGeracaoEconomia, "graficoGeracaoEconomia", listaGraficoGeracaoEconomia, "", "R$", "");
        setGraficoGeracaoEconomia(graficoGeracaoEconomiaAtualizado);

        const graficoGeracaoRetornoFinanceiroAtualizado = montarGraficoClusteredColumnMensal(
            state,
            graficoGeracaoRetornoFinanceiro,
            "graficoGeracaoRetornoFinanceiro",
            listaGraficoGeracaoEconomia.map((item) => ({
                mes: item.mes,
                anos: item.anos.map((tipo) => {
                    const investimentoAno = parseFloat((listaGeracaoContrato.find((geracao) => geracao.ano == tipo.ano) || {}).valor_investido) || 0;
                    return {
                        ano: tipo.ano,
                        valor: tipo.valor > 0 && investimentoAno > 0 ? (parseFloat(tipo.valor) * 100) / investimentoAno : 0,
                    };
                }),
            })),
            "",
            "",
            "%"
        );

        setGraficoGeracaoRetornoFinanceiro(graficoGeracaoRetornoFinanceiroAtualizado);

        // let valorInvestido =
        // geracaoContrato && geracaoContrato.valor_investido > 0 ? parseFloat(geracaoContrato.valor_investido) : 0;

        //Calcula o retorno financeiro anual
        let listaEconomiaAnual = [];

        //cria uma lista com o retorno financeiro, o valor inverstido no ano e o ano em si
        // [{
        //     agrupador: "Retorno"
        //     ano: 2022
        //     valor: 96692.82767
        //     valorInvestidoAno: 1418658
        // }]
        listaGraficoGeracaoEconomia.forEach((item) => {
            item.anos.forEach((ano) => {
                const anoJaEstaNaLista = listaEconomiaAnual.find((retorno) => retorno.ano == ano.ano);
                if (anoJaEstaNaLista) {
                    anoJaEstaNaLista.valor += parseFloat(ano.valor);
                } else {
                    const geracao = listaGeracaoContrato.find((geracao) => geracao.ano == ano.ano) || {};
                    // console.log(geracao)
                    const competencia = geracao.competencia_inicio ? String(geracao.competencia_inicio).substring(4, 6) + "/" + String(geracao.competencia_inicio).substring(0, 4) : "";
                    const competenciaAmpliacao = geracao.competencia_ampliacao ? String(geracao.competencia_ampliacao).substring(4, 6) + "/" + String(geracao.competencia_ampliacao).substring(0, 4) : "";
                    const valorAmpliacao = parseFloat(geracao.valor_ampliacao) || 0;
                    const investimento = parseFloat(geracao.valor_investido) || 0;
                    listaEconomiaAnual.push({
                        agrupador: "Retorno",
                        ano: ano.ano,
                        valor: parseFloat(ano.valor),
                        valorInvestidoAno: investimento,
                        competencia,
                        competenciaAmpliacao,
                        valorAmpliacao,
                        anoInicioGeracao: listaGeracaoContrato[0].ano 
                    });
                }
            });
        });
        // console.log({listaGraficoGeracaoRetornoFinanceiro})
        // listaGraficoGeracaoRetornoFinanceiro.sort((a, b) => {
        //     if (a.ano == "Início") {
        //         return -1;
        //     } else if (b.ano == "Início") {
        //         return 1;
        //     } else {
        //         return a.ano > b.ano ? 1 : -1;
        //     }
        // });

        // console.log({listaGraficoGeracaoEconomia, listaGraficoGeracaoRetornoFinanceiro})

        // let economiaTotal = 0;
        // let listaGraficoGeracaoEconomiaAnual = listaGraficoGeracaoRetornoFinanceiro
        //     .filter((retorno) => retorno.ano != "Início")
        //     .map((retorno, i) => {
        //         // economiaTotal += retorno.valor;

        //         return {
        //             ...retorno,
        //             valor: retorno.valor,
        //         };
        //     });

        const graficoGeracaoEconomiaTotalAtualizado = montarGraficoClusteredColumnAnual(state, graficoGeracaoEconomiaTotal, "graficoGeracaoEconomiaTotal", [{ anos: listaEconomiaAnual }], "", "R$", "");
        setGraficoGeracaoEconomiaTotal(graficoGeracaoEconomiaTotalAtualizado);

        let economiaTotal = 0;
        const listaRetornoFinanceiroAnual = [];
        listaEconomiaAnual
            .sort((a, b) => a.ano - b.ano)
            .forEach((retorno, i) => {
                economiaTotal += retorno.valor;
                if ((retorno.anoInicioGeracao < retorno.ano && i == 0) || (retorno.ano == retorno.anoInicioGeracao)) {
                    listaRetornoFinanceiroAnual.push({
                        ...retorno,
                        ano: `Valor Investido Inicial ${retorno.competencia} = R$ ${adicionarPontosMilhar(retorno.valorInvestidoAno)}\nValor Resultante ${retorno.ano} = R$${adicionarPontosMilhar(
                            economiaTotal - retorno.valorInvestidoAno
                        )}\n(Desc. Economias Iniciais)`,
                        valor: economiaTotal - retorno.valorInvestidoAno, // valorInvestidoAno já tem acumulada as ampliações
                    });
                } else {
                    const textoAmpliacao = retorno.competenciaAmpliacao
                    ? `\nAmpliação (invest. R$ ${adicionarPontosMilhar(retorno.valorAmpliacao)} em ${retorno.competenciaAmpliacao})\n(Acresc. Novo Invest. e Desc. Economias do Ano)`
                    : "";
                    const textoAno = `${retorno.ano}: ${adicionarPontosMilhar(economiaTotal - retorno.valorInvestidoAno)}`;
                    // console.log({textoAmpliacao})
                    listaRetornoFinanceiroAnual.push({
                        ...retorno,
                        ano: textoAmpliacao ? `                       ${textoAno}${textoAmpliacao}` : textoAno,
                        valor: economiaTotal - retorno.valorInvestidoAno, // valorInvestidoAno já tem acumulada as ampliações
                    });
                }
            });
        // console.log(listaRetornoFinanceiroAnual);
        const graficoGeracaoAnaliseRetornoFinanceiroAnualAtualizado = montarGraficoClusteredColumnAnual(
            state,
            graficoGeracaoAnaliseRetornoFinanceiroAnual,
            "graficoGeracaoAnaliseRetornoFinanceiroAnual",
            [{ anos: [...listaRetornoFinanceiroAnual] }],
            "",
            "R$",
            "",
            true,
            false,
            false
        );
        setGraficoGeracaoAnaliseRetornoFinanceiroAnual(graficoGeracaoAnaliseRetornoFinanceiroAnualAtualizado);
    }

    function atualizarListaGraficoGeracaoEconomiaPonta() {
        const graficoGeracaoEconomiaPontaAtualizado = montarGraficoClusteredColumnMensal(state, graficoGeracaoEconomiaPonta, "graficoGeracaoEconomiaPonta", listaGraficoGeracaoEconomiaPonta, "", "R$", "");
        setGraficoGeracaoEconomiaPonta(graficoGeracaoEconomiaPontaAtualizado);
    }

    function atualizarListaGraficoGeracaoConsumoInstantaneo() {
        const graficoGeracaoConsumoInstantaneoAtualizado = montarGraficoClusteredColumnMensal(
            state,
            graficoGeracaoConsumoInstantaneo,
            "graficoGeracaoConsumoInstantaneo",
            listaGraficoGeracaoConsumoInstantaneo,
            "",
            "kWh",
            ""
        );
        setGraficoGeracaoConsumoInstantaneo(graficoGeracaoConsumoInstantaneoAtualizado);
    }

    let demandaMesInicial = contratoDemanda && contratoDemanda.competencia_inicio ? String(contratoDemanda.competencia_inicio).substring(4, 6) : "";
    let demandaMesFinal = contratoDemanda && contratoDemanda.competencia_fim ? String(contratoDemanda.competencia_fim).substring(4, 6) : "";

    let anos = [];
    listaGraficoGeracaoQuantidadeAnual.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anos.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anos.push(ano.ano);
            }
        });
    });

    anos = anos.sort((a, b) => (a < b ? -1 : 1));

    let listaGraficoGeracaoAvaliacaoProducaoAnualMapeada = listaGraficoGeracaoAvaliacaoProducaoAnual.map((item) => ({
        ...item,
        anos: item.anos.filter((itemAno) => !itemAno.ano.includes("indice")),
    }));

    let desempenhoTotalAnualProjetado = 0;
    let desempenhoTotalAnualGerado = 0;
    listaGraficoGeracaoAvaliacaoProducaoAnualMapeada.forEach((item, i) => {
        let projetado = item.anos.filter((ano) => ano.ano == "Projetado Atual")[0] ? parseFloat(item.anos.filter((ano) => ano.ano == "Projetado Atual")[0].valor) : 0;
        let gerados = item.anos.filter((ano) => ano.ano == new Date().getFullYear() && parseFloat(ano.valor) > 0);

        if (gerados.length > 0) {
            desempenhoTotalAnualProjetado += projetado;
        }
        gerados.forEach((gerado) => {
            desempenhoTotalAnualGerado += parseFloat(gerado.valor);
        });
    });

    let desempenhoAnual = desempenhoTotalAnualGerado > 0 && desempenhoTotalAnualProjetado > 0 ? (desempenhoTotalAnualGerado * 100) / desempenhoTotalAnualProjetado : 0;

    let desempenhoTotalGlobalProjetado = 0;
    let desempenhoTotalGlobalGerado = 0;
    listaGraficoGeracaoAvaliacaoProducaoAnual.forEach((item, i) => {
        let projetados = item.anos.filter((ano) => ano.ano.includes("indice"));
        let gerados = item.anos.filter((ano) => ano.ano != "Projetado Inicial" && ano.ano != "Projetado Atual" && !ano.ano.includes("indice"));

        projetados.forEach((projetado) => {
            if (gerados.filter((gerado) => projetado.ano.includes(gerado.ano) && gerado.valor > 0).length > 0) {
                desempenhoTotalGlobalProjetado += parseFloat(projetado.valor);
            }
        });

        gerados.forEach((gerado) => {
            desempenhoTotalGlobalGerado += parseFloat(gerado.valor);
        });
    });

    let desempenhoGlobal = desempenhoTotalGlobalGerado > 0 ? (desempenhoTotalGlobalGerado * 100) / desempenhoTotalGlobalProjetado : 0;

    let totalAntes = 0;
    let totalDepois = 0;
    listaGraficoGeracaoComparativoConsumo.forEach((item, i) => {
        let antes = item.tipos.filter((tipo) => tipo.tipo == "Antes")[0] ? parseFloat(item.tipos.filter((tipo) => tipo.tipo == "Antes")[0].valor) : 0;
        let depois = item.tipos.filter((tipo) => tipo.tipo == "Depois")[0] ? parseFloat(item.tipos.filter((tipo) => tipo.tipo == "Depois")[0].valor) : 0;

        if (antes && depois) {
            totalAntes += antes;
            totalDepois += depois;
        }
    });

    let antesDepois = totalDepois > 0 ? (totalDepois * 100) / totalAntes : 0;

    let economia = 0;
    listaGraficoGeracaoEconomia.forEach((item, i) => {
        item.anos.forEach((ano, i) => {
            economia += parseFloat(ano.valor);
        });
    });

    const totalInvestido = parseFloat((listaGeracaoContrato[listaGeracaoContrato.length - 1] || {}).valor_investido) || 0; //a ultima tem o acumulado de todos os anos
    let retornoFinanceiro = economia > 0 && totalInvestido > 0 ? (economia * 100) / totalInvestido : 0;

    let economiaPonta = 0;
    listaGraficoGeracaoEconomiaPonta.forEach((item, i) => {
        item.anos.forEach((ano, i) => {
            economiaPonta += parseFloat(ano.valor);
        });
    });

    function renderizarLinhasTabelaGeracaoProjetadaXRealidade({ somenteProjetado, somenteAnos }) {
        const linhas = (listaGraficoGeracaoAvaliacaoProducaoAnual[0] && listaGraficoGeracaoAvaliacaoProducaoAnual[0].anos) || [];

        return linhas
            .filter((linha) => {
                if (somenteProjetado) return linha.ano.includes("Projetado");
                if (somenteAnos) return !linha.ano.includes("Projetado");
            })
            .sort((a, b) => {
                if (somenteProjetado) return b.ano.localeCompare(a.ano, undefined, { sensitivity: "base" });
                if (somenteAnos) return a.ano.ano - b.ano.ano;
            })
            .map((ano, i) => {
                let totalGerado = 0;
                let totalProjetado = 0;

                if (ano.ano.includes("indice")) return;
                //Buscar Projetado
                listaGraficoGeracaoAvaliacaoProducaoAnual.forEach((mes) => {
                    mes.anos
                        .filter((anoMes) => anoMes.ano == `indice-${ano.ano}`)
                        .forEach((anoMes) => {
                            if (mes.anos.find((anoMes) => anoMes.ano == ano.ano && anoMes.valor > 0)) {
                                totalProjetado += parseFloat(anoMes.valor || 0);
                            }
                        });
                });

                return (
                    <Tr key={ano.ano}>
                        <Td color="#fff">{ano.ano}</Td>
                        {listaGraficoGeracaoAvaliacaoProducaoAnual.map((mes) => {
                            if (mes.anos.find((anoMes) => anoMes.ano == ano.ano)) {
                                return mes.anos
                                    .filter((anoMes) => anoMes.ano == ano.ano)
                                    .map((anoMes) => {
                                        totalGerado += parseFloat(anoMes.valor || 0);

                                        return (
                                            <Td key={anoMes.ano} alignCenter color="#fff">
                                                {parseFloat(anoMes.valor || 0)
                                                    .toFixed(2)
                                                    .replace(".", ",")}
                                            </Td>
                                        );
                                    });
                            } else {
                                return (
                                    <Td key={ano} alignCenter color="#fff">
                                        0,00
                                    </Td>
                                );
                            }
                        })}

                        {(() => {
                            let listaMeses = [];
                            for (var i = listaGraficoGeracaoAvaliacaoProducaoAnual.length; i < 12; i++) {
                                listaMeses.push(i);
                            }

                            return listaMeses.map((mes) => (
                                <Td key={mes} alignCenter color="#fff">
                                    0,00
                                </Td>
                            ));
                        })()}
                        <Td alignCenter color="#fff">
                            {totalProjetado > 0 ? `${((totalGerado * 100) / totalProjetado).toFixed(2).replace(".", ",")}%` : "-"}
                        </Td>
                    </Tr>
                );
            });
    }

    return (
        <div>
            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                        textAlign: "center",
                    }}
                >
                    <div style={{ marginTop: 10, fontSize: 16 }}>Geração Projetada X Realidade (kWh)</div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#006d2e",
                                width: 300,
                                paddingTop: 8,
                                paddingBottom: 8,
                            }}
                        >
                            Desempenho Global: {FormatUtils.formatarValorTela(desempenhoGlobal || 0, 2)}%
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 2,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#006d2e",
                                width: 300,
                                paddingTop: 8,
                                paddingBottom: 8,
                            }}
                        >
                            Desempenho Anual: {FormatUtils.formatarValorTela(desempenhoAnual || 0, 2)}%
                        </div>
                    </div>
                    <div
                        id="graficoGeracaoAvaliacaoProducaoMensal"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: 400,
                            marginTop: -20,
                        }}
                    ></div>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th width={200}>Produção em kWh</Th>
                                    <Th alignCenter>Janeiro</Th>
                                    <Th alignCenter>Fevereiro</Th>
                                    <Th alignCenter>Março</Th>
                                    <Th alignCenter>Abril</Th>
                                    <Th alignCenter>Maio</Th>
                                    <Th alignCenter>Junho</Th>
                                    <Th alignCenter>Julho</Th>
                                    <Th alignCenter>Agosto</Th>
                                    <Th alignCenter>Setembro</Th>
                                    <Th alignCenter>Outubro</Th>
                                    <Th alignCenter>Novembro</Th>
                                    <Th alignCenter>Dezembro</Th>
                                    <Th alignCenter>Desempenho</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {renderizarLinhasTabelaGeracaoProjetadaXRealidade({
                                    somenteProjetado: true,
                                    somenteAnos: false,
                                })}
                                {renderizarLinhasTabelaGeracaoProjetadaXRealidade({
                                    somenteProjetado: false,
                                    somenteAnos: true,
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoGeracaoAvaliacaoProducaoAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoGeracaoQuantidadeAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoGeracaoQuantidadeMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th width={200}>UC</Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null,
                                        }}
                                    >
                                        Janeiro
                                        {demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null,
                                        }}
                                    >
                                        Fevereiro
                                        {demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null,
                                        }}
                                    >
                                        Março
                                        {demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null,
                                        }}
                                    >
                                        Abril
                                        {demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null,
                                        }}
                                    >
                                        Maio
                                        {demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null,
                                        }}
                                    >
                                        Junho
                                        {demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null,
                                        }}
                                    >
                                        Julho
                                        {demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null,
                                        }}
                                    >
                                        Agosto
                                        {demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null,
                                        }}
                                    >
                                        Setembro
                                        {demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null,
                                        }}
                                    >
                                        Outubro
                                        {demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null,
                                        }}
                                    >
                                        Novembro
                                        {demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null,
                                        }}
                                    >
                                        Dezembro
                                        {demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : ""}
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {(anos || [])
                                    .sort((a, b) => (a.ano < b.ano ? -1 : 1))
                                    .map((ano, i) => (
                                        <Tr key={i}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoGeracaoQuantidadeAnual.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length > 0) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {(() => {
                                                let listaMeses = [];
                                                for (var i = listaGraficoGeracaoQuantidadeAnual.length; i < 12; i++) {
                                                    listaMeses.push(i);
                                                }

                                                return listaMeses.map((mes) => (
                                                    <Td key={mes} alignCenter color="#fff">
                                                        0,00
                                                    </Td>
                                                ));
                                            })()}
                                        </Tr>
                                    ))}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") || buscarPropriedadeUcFiltrada("remota") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoGeracaoSaldo" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") || buscarPropriedadeUcFiltrada("remota") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                        textAlign: "center",
                    }}
                >
                    <div style={{ marginTop: 10, fontSize: 16 }}>Consumo (kWh): Antes X Depois</div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#006d2e",
                                width: 300,
                                paddingTop: 8,
                                paddingBottom: 8,
                            }}
                        >
                            Consumo no Período: {FormatUtils.formatarValorTela(antesDepois || 0, 2)}%
                        </div>
                    </div>
                    <div
                        id="graficoGeracaoComparativoConsumo"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: 400,
                            marginTop: -20,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                        textAlign: "center",
                    }}
                >
                    <div style={{ marginTop: 10, fontSize: 16 }}>Simulação de Consumo Instantâneo (kWh)</div>
                    <div
                        id="graficoGeracaoConsumoInstantaneo"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: 400,
                            marginTop: -20,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") || buscarPropriedadeUcFiltrada("remota") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                        textAlign: "center",
                    }}
                >
                    <div style={{ marginTop: 10, fontSize: 16 }}>Economia Anual (R$)</div>
                    <div
                        id="graficoGeracaoEconomiaTotal"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: 400,
                            marginTop: -20,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") || buscarPropriedadeUcFiltrada("remota") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                        textAlign: "center",
                    }}
                >
                    <div style={{ marginTop: 10, fontSize: 16 }}>Economia Mensal (R$)</div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#006d2e",
                                width: 300,
                                paddingTop: 8,
                                paddingBottom: 8,
                            }}
                        >
                            Economia Total: R$ {FormatUtils.formatarValorTela(economia || 0, 2)}
                        </div>
                    </div>
                    <div
                        id="graficoGeracaoEconomia"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: 400,
                            marginTop: -20,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display:
                        buscarPropriedadeUcFiltrada() &&
                        (buscarPropriedadeUcFiltrada("geracao") || buscarPropriedadeUcFiltrada("remota")) &&
                        ([1, 4].includes(parseInt(buscarPropriedadeUcFiltrada("id_grupo_faturamento"))) || buscarPropriedadeUcFiltrada("id_grupo_faturamento") == 3)
                            ? ""
                            : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                        textAlign: "center",
                    }}
                >
                    <div style={{ marginTop: 10, fontSize: 16 }}>Economia Horário Ponta (R$)</div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#006d2e",
                                width: 300,
                                paddingTop: 8,
                                paddingBottom: 8,
                            }}
                        >
                            Economia Ponta Total: R$ {FormatUtils.formatarValorTela(economiaPonta || 0, 2)}
                        </div>
                    </div>
                    <div
                        id="graficoGeracaoEconomiaPonta"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: 400,
                            marginTop: -20,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: buscarPropriedadeUcFiltrada() && buscarPropriedadeUcFiltrada("geracao") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                        textAlign: "center",
                    }}
                >
                    <div style={{ marginTop: 10, fontSize: 16 }}>Retorno Financeiro (%)</div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#006d2e",
                                width: 300,
                                paddingTop: 8,
                                paddingBottom: 8,
                            }}
                        >
                            Retorno Total: {FormatUtils.formatarValorTela(retornoFinanceiro || 0, 2)}%
                        </div>
                    </div>
                    <div
                        id="graficoGeracaoRetornoFinanceiro"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: 400,
                            marginTop: -20,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("geracao") ? "" : "none",
                }}
            >
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                        textAlign: "center",
                    }}
                >
                    <div style={{ marginTop: 10, fontSize: 16 }}>Análise do Retorno Financeiro (R$)</div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#006d2e",
                                width: 300,
                                paddingTop: 8,
                                paddingBottom: 8,
                            }}
                        >
                            Retorno Total: {FormatUtils.formatarValorTela(retornoFinanceiro || 0, 2)}%
                        </div>
                    </div>
                    <div
                        id="graficoGeracaoAnaliseRetornoFinanceiroAnual"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: 400,
                            marginTop: -20,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
    filtro: state.analise.filtro,
    contratoDemanda: state.analise.contratoDemanda,
    listaGraficoGeracaoQuantidadeAnual: state.analise.listaGraficoGeracaoQuantidadeAnual,
    listaGraficoGeracaoSaldo: state.analise.listaGraficoGeracaoSaldo,
    listaGraficoGeracaoAvaliacaoProducaoAnual: state.analise.listaGraficoGeracaoAvaliacaoProducaoAnual,
    listaGraficoGeracaoComparativoConsumo: state.analise.listaGraficoGeracaoComparativoConsumo,
    listaGraficoGeracaoEconomia: state.analise.listaGraficoGeracaoEconomia,
    listaGraficoGeracaoEconomiaPonta: state.analise.listaGraficoGeracaoEconomiaPonta,
    listaGraficoGeracaoConsumoInstantaneo: state.analise.listaGraficoGeracaoConsumoInstantaneo,
    listaGeracaoContrato: state.analise.listaGeracaoContrato,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDemanda,
            getListaGraficoGeracaoAvaliacaoProducao,
            getListaGraficoGeracaoSaldo,
            getListaGraficoGeracaoQuantidadeAnual,
            getGeracaoCadastro,
            getListaGraficoGeracaoComparativoConsumo,
            getListaGraficoGeracaoEconomia,
            getListaGraficoGeracaoEconomiaPonta,
            getListaGraficoGeracaoConsumoInstantaneo,
            setFiltro,
        },
        dispatch
    );
const AnaliseMicroMiniGeracao = connect(mapStateToProps, mapDispatchToProps)(analiseMicroMiniGeracao);
export { AnaliseMicroMiniGeracao };
