import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";

export function setModoTela(modo, registro = {}) {
    return {
        type: "GERACAO_LEITURA_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setAguardando(aguardando) {
    return {
        type: "GERACAO_LEITURA_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function setFiltro(filtro) {
    return {
        type: "GERACAO_LEITURA_FILTRO",
        payload: filtro,
    };
}
export function setDataGrafico(data) {
    return {
        type: "GERACAO_LEITURA_SET_DATA_GRAFICO",
        payload: data,
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        //evita buscar 2 vezes enquanto jáesta buscando a primeira vez
        if (getState().geracaoLeitura.aguardando) return {};
        dispatch({
            type: "GERACAO_LEITURA_SET_AGUARDANDO",
            payload: true,
        });
        //ALTERADO PRA CARREGAR A LISTA INTEIRA UMA VEZ E DEPOIS FILTRAR O QUE JÁ ESTA CARREGADO NO FRONT
        try {
            const { id_cliente, id_unidade_consumidora, id_agrupador, agrupar_por_uc } = getState().geracaoLeitura.filtro;
            const resp = await axios.get(
                `${consts.API_URL}/geracaoLeitura?id_cliente=${id_cliente}&id_unidade_consumidora=${id_unidade_consumidora}&id_agrupador=${
                    //pode ser id_geracao_integracao (modo individual) ou id da UC (modo agrupado por UC)
                    id_agrupador
                }&agrupar_por_uc=${agrupar_por_uc}`
            );
            dispatch({
                type: "GERACAO_LEITURA_LISTADO",
                payload: resp,
            });
            dispatch({
                type: "GERACAO_LEITURA_SET_AGUARDANDO",
                payload: false,
            });
        } catch (erro) {
            console.log(erro)
            setErroAPI(erro);
        }
    };
}

export function getModulos(idUnidadeConsumidora) {
    return async (dispatch, getState) => {
        if (!idUnidadeConsumidora) {
            dispatch({
                type: "GERACAO_LEITURA_MODULOS_LISTADO",
                payload: { data: [] },
            });
            return {};
        }
        //evita buscar 2 vezes enquanto jáesta buscando a primeira vez
        if (getState().geracaoLeitura.aguardando) return {};
        dispatch({
            type: "GERACAO_LEITURA_SET_AGUARDANDO",
            payload: true,
        });
        //ALTERADO PRA CARREGAR A LISTA INTEIRA UMA VEZ E DEPOIS FILTRAR O QUE JÁ ESTA CARREGADO NO FRONT
        try {
            const resp = await axios.get(`${consts.API_URL}/geracaoComAmpliacao?id_unidade_consumidora=${idUnidadeConsumidora}`);
            dispatch({
                type: "GERACAO_LEITURA_MODULOS_LISTADO",
                payload: resp,
            });
            dispatch({
                type: "GERACAO_LEITURA_SET_AGUARDANDO",
                payload: false,
            });
        } catch (erro) {
            setErroAPI(erro);
        }
    };
}

export function setStatusVisualizado(idOuListaIds) {
    return (dispatch, getState) => {
        if (window.confirm("Deseja realmente desabilitar o alerta para o registro selecionado?"))
            axios
                .patch(`${consts.API_URL}/geracaoLeitura`, { idOuListaIds })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch({
                        type: "GERACAO_LEITURA_SET_STATUS_VISUALIZADO",
                        payload: idOuListaIds,
                    });
                })
                .catch((e) => {
                    setErroAPI(e);
                });
    };
}

export function getListaGraficoTodasIntegracoesAgrupadasPorHora({ dataGrafico, intervaloTempo }) {
    return async (dispatch, getState) => {
        const { id_cliente, id_unidade_consumidora } = getState().geracaoLeitura.filtro;
		let data = []
		try{
			const resposta = await axios.get(
				`${consts.API_URL}/geracaoLeitura/listarGraficoTodasIntegracoesAgrupadasPorIntervaloTempo?data=${dataGrafico}&id_cliente=${id_cliente}&id_unidade_consumidora=${id_unidade_consumidora}&intervalo_tempo=${intervaloTempo}`
			);
			data = resposta.data;
		}catch(erro){
			setErroAPI(erro)
		}
        dispatch({
            type: "GERACAO_LEITURA_LISTA_GRAFICO_TODAS_INTEGRACOES_AGRUPADAS_POR_HORA",
            payload: {data ,dataGrafico, intervaloTempo},
        });
    };
}

export function getListaGraficoEnergiaDia({ id_agrupador, data }) {
    return (dispatch, getState) => {
        const agruparPorUc = getState().geracaoLeitura.filtro.agrupar_por_uc;
        const resposta = axios.get(`${consts.API_URL}/geracaoLeitura/listaGraficoEnergiaDia?id_agrupador=${id_agrupador}&data=${data}&agrupar_por_uc=${agruparPorUc}`);
        dispatch({
            type: "GERACAO_LEITURA_LISTA_GRAFICO_ENERGIA_DIA",
            payload: resposta,
        });
    };
}

export function getListaGraficoEnergiaDiasMes({ id_agrupador, data }) {
    return (dispatch, getState) => {
        const agruparPorUc = getState().geracaoLeitura.filtro.agrupar_por_uc;
        try {
            const resposta = axios.get(`${consts.API_URL}/geracaoLeitura/listaGraficoEnergiaDiasMes?id_agrupador=${id_agrupador}&data=${data}&agrupar_por_uc=${agruparPorUc}`);

            dispatch({
                type: "GERACAO_LEITURA_LISTA_GRAFICO_ENERGIA_DIAS_MES",
                payload: resposta,
            });
        } catch (erro) {
            //console.log(erro);
        }
    };
}

export function getListaGraficoEnergiaMesesAno({ id_agrupador, data }) {
    return (dispatch, getState) => {
        const agruparPorUc = getState().geracaoLeitura.filtro.agrupar_por_uc;
        const resposta = axios.get(`${consts.API_URL}/geracaoLeitura/listaGraficoEnergiaMesesAno?id_agrupador=${id_agrupador}&data=${data}&agrupar_por_uc=${agruparPorUc}`);

        dispatch({
            type: "GERACAO_LEITURA_LISTA_GRAFICO_ENERGIA_MESES_ANO",
            payload: resposta,
        });
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "GERACAO_LEITURA_CLIENTE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: "GERACAO_LEITURA_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
        payload: request,
    };
}
