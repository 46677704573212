import React from "react";
import InfoBox from "../common/template/infoBox";
import Grid from "../common/layout/grid";
import solarField from "../assets/images/solar-field.svg";
import solarPanelCharge from "../assets/images/solar-panel-charge-thin.svg";
import solarPanelSun from "../assets/images/solar-panel-sun-thin.svg";
import calendarCheck from "../assets/images/calendar-check.svg";

export function GeracaoLeituraCardsTotalizadores({ usuarioLogado, lista }) {
    const totalUsinas = lista.filter((item) => !usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente).length;
    const potenciaTotalInstalada =
        Number(
            lista.reduce((acc, item) => {
                if (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) {
                    const modulosPotenciaTotal = parseFloat(item.modulos_potencia_total) >= 0 ? parseFloat(item.modulos_potencia_total) : 0;
                    const inversorPotenciaTotal = parseFloat(item.inversor_potencia_total) >= 0 ? parseFloat(item.inversor_potencia_total) : 0;
                    //considera o menor valor entre os 2 valores cadastrados
                    acc += Math.min(modulosPotenciaTotal, inversorPotenciaTotal);
                }
                return acc;
            }, 0) / 1000
        )
            .toFixed(2)
            .replace(".", ",") + " MW";

    const energiaTotalDia =
        Number(
            lista.reduce((acc, item) => {
                if (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) {
                    acc += parseFloat(item.energia_dia || 0);
                }
                return acc;
            }, 0) / 1000
        )
            .toFixed(2)
            .replace(".", ",") + " MWh";
    const energiaTotalMes =
        Number(
            lista.reduce((acc, item) => {
                if (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) {
                    acc += parseFloat(item.energia_mes || 0);
                }
                return acc;
            }, 0) / 1000
        )
            .toFixed(2)
            .replace(".", ",") + " MWh";
    return (
        <>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Nº Total de Usinas" number={totalUsinas} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                    <img
                        src={solarField}
                        style={{
                            height: 40,
                            width: 40,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Potência Total Instalada" number={potenciaTotalInstalada} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                    <img
                        src={solarPanelCharge}
                        style={{
                            height: 35,
                            width: 35,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Energia Total do Dia" number={energiaTotalDia} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                    <img
                        src={solarPanelSun}
                        style={{
                            height: 35,
                            width: 35,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Energia Total do Mês" number={energiaTotalMes} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                    <img
                        src={calendarCheck}
                        style={{
                            height: 30,
                            width: 30,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
        </>
    );
}
