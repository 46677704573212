import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

function montarGraficoHorizontalBar({
    component,
    chartDivId,
    category,
    value,
    bullet,
    bulletPosition = "in",
    bulletColor = "#000",
    lista,
    titulo = "",
    prefixo = "R$",
    sufixo = "",
    maxWidthLabel = null,
    wrapTextLabel = false,
    cores = null,
}) {
    if (component) component.dispose();

    am4core.useTheme(am4themes_animated);

    const chart = am4core.create(chartDivId, am4charts.XYChart);

    chart.language.locale = locale;

    let title = chart.titles.create();
    title.text = titulo;
    title.fontSize = 16;
    title.marginBottom = 30;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = "Gráfico";
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
        {
            label: "...",
            menu: [
                {
                    type: "png",
                    label: "PNG",
                },
            ],
        },
    ];

    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";
    chart.numberFormatter.numberFormat = "###,###,###,###.00";

    //----------------------------------------------------
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.colors.saturation = 0.8;
    if (cores) chart.colors.list = cores;
    chart.colors.step = 2; // Aumenta o espaçamento entre tons para variedade

    chart.data = [...lista];
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = category;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.minWidth = 120;
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.textAlign = "right";
    categoryAxis.renderer.labels.template.fill = am4core.color("#FFF"); //cor do texto eixo x

    // Limitar o texto do eixo Y a 20 caracteres com quebra de linha
    categoryAxis.renderer.labels.template.wrap = !!wrapTextLabel; // Ativar a quebra de linha
    categoryAxis.renderer.labels.template.maxWidth = maxWidthLabel || 300; // Definir a largura máxima permitida

    // Limitar o texto do eixo Y a 20 caracteres com quebra de linha
    categoryAxis.renderer.labels.template.adapter.add("textOutput", (text) => {
        if (text && text.length > 55) {
            return text.substring(0, 55) + "...";
        }
        return text;
    });

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.paddingBottom = 30;
    valueAxis.renderer.maxLabelPosition = 0.98;
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.labels.template.textAlign = "middle";
    valueAxis.renderer.labels.template.fill = am4core.color("#FFF"); //cor do texto eixo x
    valueAxis.extraMax = 0.1;

    const series = chart.series.push(new am4charts.ColumnSeries());

    series.dataFields.categoryY = category;
    series.dataFields.valueX = value;
    series.tooltipText = `{categoryY}:\n [bold]${prefixo} {valueX.formatNumber("###,###,###,###.00")}[/]`;

    // Defina um limite de largura e habilite a quebra de linha no tooltip
    series.tooltip.maxWidth = 300; // Define a largura máxima do tooltip em pixels
    series.tooltip.label.wrap = true; // Ativa a quebra de linha
    series.tooltip.label.truncate = false; // Evita que o texto seja truncado

    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 1000;
    series.sequencedInterpolationDelay = 100;
    series.columns.template.strokeOpacity = 0;

    // Definir altura mínima da barra
    series.columns.template.maxHeight = 50; // Defina a altura mínima desejada em pixels

    chart.cursor = new am4charts.XYCursor();
    // chart.cursor.behavior = "zoomY";

    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    series.columns.template.adapter.add("fill", (fill, target) => {
        return chart.colors.getIndex(target.dataItem.index);
    });

    const categoryLabel = series.bullets.push(new am4charts.LabelBullet());

    //se tem sufixo é a unidade de medida do valor do bullet (geralmente valor normal é prefixo R$ e o valor percentual com sufixo %)
    categoryLabel.label.text = sufixo ? `[bold]{${bullet}.formatNumber(###)}${sufixo}` : `[bold]${prefixo} {${bullet}.formatNumber(###)}`;
    categoryLabel.label.fontSize = 16;
    categoryLabel.label.horizontalCenter = bulletPosition === "in" ? "right" : "false";
    categoryLabel.label.dx = bulletPosition === "in" ? -10 : 10;
    categoryLabel.label.fill = am4core.color(bulletColor);

    if (bulletPosition === "auto") {
        // Configura o posicionamento e a cor condicional do rótulo
        categoryLabel.label.adapter.add("dx", (dx, target) => {
            const valueWidth = target.measuredWidth; // largura do valor do texto
            const columnWidth = target.dataItem.column.pixelWidth; // largura da barra

            // Se a barra for menor que o texto, posiciona o valor fora da barra e define a cor branca
            if (columnWidth < valueWidth) {
                target.horizontalCenter = "false";
                target.fill = am4core.color("#FFF"); // Texto branco fora da barra
                return 10; // Posiciona o texto fora da barra
            } else {
                target.horizontalCenter = "right";
                target.fill = am4core.color("#000"); // Texto preto dentro da barra
                return -2; // Centraliza o texto dentro da barra
            }
        });
    }
    // 	Portanto, sempre que um rótulo não couber no espaço alocado, duas coisas podem acontecer:

    // O rótulo é truncado com reticências;
    // Ou, se não houver espaço para rótulo truncado, ele ficará completamente oculto.
    // As configurações de rótulo responsáveis ​​pelo acima são truncatee hideOversizedrespectivamente.

    // Portanto, se você estiver vendo rótulos cortados ou desaparecendo, simplesmente redefina ambos os itens acima para false:
    categoryLabel.label.truncate = false;
    categoryLabel.label.hideOversized = false;

    // Remover tooltips dos eixos
    categoryAxis.tooltip.disabled = true;
    valueAxis.tooltip.disabled = true;

    return chart;
}
export { montarGraficoHorizontalBar };
