import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import ContentCard from "../../common/template/contentCard";
import ContentCardBody from "../../common/template/contentCardBody";
import ContentCardFooter from "../../common/template/contentCardFooter";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Button from "../../common/button/button";
import BotaoExcluir from "../../common/button/botaoExcluir";
import Form from "../../common/form/form";
import ButtonTable from "../../common/table/buttonTable";
import FileChooser from "../../common/form/fileChooser/fileChooser";
import { setModoTela, initFormFaturaAdicional, dividirPdfEmPaginas } from "./faturaItemActions";
import { setArquivoPDF } from "../../displayPDF/displayPDFActions";

class FaturaAdicionalForm extends Component {
    render() {
        const produto = this.props.listaProduto.find((item) => item.id == this.props.formularioValues.id_produto);
        const readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";
        return (
            <>
                <ContentCard>
                    <Form event={this.props.handleSubmit}>
                        <ContentCardBody>
                            <Grid cols="12 12 6 6" style={{ paddingLeft: 0, paddingRight: 6 }}>
                                <FileChooser
                                    readOnly={readOnly}
                                    nome=""
                                    base64=""
                                    label={produto.valor}
                                    onFileSelect={async (base64ComNomeJSON) => {
                                        const paginas = (await dividirPdfEmPaginas(base64ComNomeJSON.base64)) || [];
                                        this.props.initFormFaturaAdicional({
                                            ...this.props.formularioValues,
                                            listaPdfBase64: [...this.props.formularioValues.listaPdfBase64, ...paginas],
                                        });
                                    }}
                                />
                            </Grid>
                            <Row>
                                {this.props.formularioValues.listaPdfBase64.length ? (
                                    this.props.formularioValues.listaPdfBase64.map((item, i) => {
                                        return (
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 20, border: "1px solid #ccc", margin: 5 }}>
                                                {this.props.usuarioLogado.acesso_fatura_adicional_excluir ? <div style={{ paddingLeft: 58 }}>
                                                    <BotaoExcluir
                                                        event={() => {
                                                            if (!window.confirm("Deseja realmente remover o arquivo?")) return;
                                                            this.props.initFormFaturaAdicional({
                                                                ...this.props.formularioValues,
                                                                listaPdfBase64: this.props.formularioValues.listaPdfBase64.filter((base64) => base64 !== item),
                                                            });
                                                        }}
                                                    />
                                                </div> : null}
                                                <ButtonTable
                                                    style={{ margin: "5px 20px" }}
                                                    title="Visualizar PDF"
                                                    type={"primary"}
                                                    icon={"fas fa-file-pdf"}
                                                    disabled={false}
                                                    visible={true}
                                                    event={() => {
                                                        const nomePdf = this.props.formularioValues.nomePdf + "_Página_" + (i + 1);
                                                        this.props.setArquivoPDF(item, nomePdf);
                                                    }}
                                                />
                                                <b>Página {i + 1}</b>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p style={{ padding: 20 }}>Ainda não há arquivos PDF</p>
                                )}
                            </Row>
                        </ContentCardBody>
                        <ContentCardFooter>
                            <Row alignCenter>
                                <Grid cols="6 6 4 3">{this.props.excluir ? <Button text="Excluir" submit type={"danger"} /> : <Button text={"Salvar"} submit type={"success"} disabled={this.props.visualizar} />}</Grid>
                                <Grid cols="6 6 4 3">
                                    <Button text="Voltar" type={"warning"} event={() => this.props.setModoTela("lista")} />
                                </Grid>
                            </Row>
                        </ContentCardFooter>
                    </Form>
                </ContentCard>
            </>
        );
    }
}

FaturaAdicionalForm = reduxForm({
    form: "faturaAdicionalForm",
    destroyOnUnmount: false,
})(FaturaAdicionalForm);
const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    formularioValues: getFormValues("faturaAdicionalForm")(state),
    registro: state.faturaItem.registro,
    listaProduto: state.faturaItem.listaProduto,
    modoTela: state.faturaItem.modoTela,
    aguardando: state.fatura.aguardando,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initFormFaturaAdicional,
            setArquivoPDF,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FaturaAdicionalForm);
