const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: "lista",
	lista: [],
	listaEstado: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "CONCESSIONARIA_MODO_TELA":
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
			};

		case "CONCESSIONARIA_SET_AGUARDANDO":
			return { ...state, aguardando: action.payload };

		case "CONCESSIONARIA_FILTRO":
			return { ...state, filtro: action.payload };

		case "CONCESSIONARIA_LISTADO":
			return {
				...state,
				lista: action.payload.data,
			};

		case "CONCESSIONARIA_UF_SELECT_LISTADO":
			return {
				...state,
				listaEstado: action.payload.data,
			};

		default:
			return state;
	}
};
