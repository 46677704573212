import * as am4core from "@amcharts/amcharts4/core";
//deixa botoes da barra de rolagem do gráfico quadrados
export function customizeChartGrip(grip) {
    // Remove default grip image
    grip.icon.disabled = true;

    // Disable background
    grip.background.disabled = true;

    // Add rotated rectangle as bi-di arrow
    const img = grip.createChild(am4core.Rectangle);
    img.width = 15;
    img.height = 15;
    img.fill = am4core.color("#0070cc");
    img.stroke = am4core.color("#0070cc");
    img.rotation = 45;
    img.align = "center";
    img.valign = "middle";

    // Add vertical bar
    const line = grip.createChild(am4core.Rectangle);
    line.height = 60;
    line.width = 3;
    line.fill = am4core.color("#0070cc");
    line.stroke = am4core.color("#0070cc");
    line.align = "center";
    line.valign = "middle";
}
