import React from "react";
import InfoBox from "../common/template/infoBox";
import Grid from "../common/layout/grid";
import charging90 from "../assets/images/battery-level-90-charging.svg";
import charging50 from "../assets/images/battery-level-50-charging.svg";
import charging20 from "../assets/images/battery-level-20-charging.svg";

export function GeracaoLeituraCardsStatusProducao({ telaDetalhesAtiva, usuarioLogado, lista }) {
    const totalUsinasOperandoNormalmente = lista.filter((item) => {
        //se NÃO estiver na tela de detalhes considera também a hora pra validar o status
        return telaDetalhesAtiva
            ? item.status && item.potencia_instantanea > 0 && (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente)
            : item.status && item.potencia_instantanea > 0 && (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) && new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora);
    }).length;
    const totalUsinasSemComunicacao = lista
        .filter((item) => !usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente)
        .filter((item) => {
            //se NÃO estiver na tela de detalhes considera também a hora pra validar o status
            return telaDetalhesAtiva ? !(item.status && item.potencia_instantanea > 0) : !(item.status && item.potencia_instantanea > 0 && new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora));
        }).length;

    const totalUsinasProducaoOtima = lista.filter(
        (item) =>
            (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) &&
            item.status &&
            item.potencia_instantanea > 0 &&
            new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora) &&
            item.percentual_producao >= 85
    ).length;

    const totalUsinasProducaoModerada = lista.filter(
        (item) =>
            (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) &&
            item.status &&
            item.potencia_instantanea > 0 &&
            new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora) &&
            item.percentual_producao > 50 &&
            item.percentual_producao < 85
    ).length;
    const totalUsinasProducaoBaixa = lista.filter(
        (item) =>
            (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) &&
            item.status &&
            item.potencia_instantanea > 0 &&
            new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora) &&
            item.percentual_producao > 0 &&
            item.percentual_producao <= 50
    ).length;
    //----------------------------------------------------------------------
    return (
        <>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox icon="fas fa-check" text="Operando Normalmente" number={totalUsinasOperandoNormalmente} twoColors="#3EFF3E" minHeight={50} />
            </Grid>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox icon="fas fa-exclamation-triangle" text="Sem Comunicação ou Desligado" number={totalUsinasSemComunicacao} twoColors="#F56969" minHeight={50} />
            </Grid>
            <Grid cols="12 12 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Produção Ótima" number={totalUsinasProducaoOtima} twoColors="#4AE30E" minHeight={50}>
                    <img
                        src={charging90}
                        style={{
                            height: 30,
                            width: 30,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 12 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Produção Moderada" number={totalUsinasProducaoModerada} twoColors="#FFE53C" minHeight={50}>
                    <img
                        src={charging50}
                        style={{
                            height: 30,
                            width: 30,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 12 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Produção Baixa" number={totalUsinasProducaoBaixa} twoColors="#FF3838" minHeight={50}>
                    <img
                        src={charging20}
                        style={{
                            height: 30,
                            width: 30,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
        </>
    );
}
