import React, { useEffect, useState } from "react";
import Row from "../common/layout/row";
import Select from "../common/form/select";
import Grid from "../common/layout/grid";
import DateFormat from "../common/dateFormat/DateFormat";

export function GeracaoLeituraGraficoGeral({ atualizarLista }) {
    const [dataGrafico, setDataGrafico] = useState(DateFormat.getDataAtual().replace(/\//g, "-"));
    const [intervaloTempo, setIntervaloTempo] = useState(15);

    useEffect(() => {
        atualizarLista({ dataGrafico, intervaloTempo });
    }, [dataGrafico, intervaloTempo]);

    const desabilitarBotaoAvancarData = dataGrafico === new Date().toISOString().substring(0, 10);

    function voltarAvancarDataGrafico(tempo) {
        const novaData = new Date(dataGrafico);
        novaData.setUTCDate(novaData.getUTCDate() + tempo);
        setDataGrafico(novaData.toISOString().substring(0, 10));
    }
    return (
        <Row>
            <Grid
                cols="12 8 8 8"
                style={{
                    display: "flex",
                    minWidth: "fit-content",
                    justifyContent: window.innerWidth < 768 ? 'center': "flex-end",
                    alignItems: "center",
                    paddingTop: 10,
                }}
            >
                <button
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.2rem",
                        fontWeight: 500,
                        boxShadow: false ? "inset 0 0 16px 1px #1a2f2a" : "0 0 10px 1px #1a2f2a",
                        color: "#fff",
                        width: 40,
                        height: 40,
                        borderRadius: "100%",
                        backgroundColor: "#1e3e49",
                        border: "none",
                        marginInline: 20,
                    }}
                    onFocus={(e) => {
                        e.target.style.outline = "none";
                    }}
                    onClick={() => {
                        voltarAvancarDataGrafico(-1);
                    }}
                    disabled={false}
                >
                    {"<"}
                </button>
                <input
                    style={{
                        fontSize: "0.9rem",
                        padding: 7,
                        border: "1px solid #1a2f2a",
                        backgroundColor: "#1e3e49",
                        color: "#fff",
                        borderRadius: 10,
                    }}
                    required
                    type="date"
                    value={dataGrafico}
                    max={DateFormat.getDataAtual().replace(/\//g, "-")}
                    onFocus={(e) => (e.target.style.outline = "none")}
                    onChange={(e) => {
                        const valorData = e.target.value || DateFormat.getDataAtual().replace(/\//g, "-");
                        setDataGrafico(valorData);
                    }}
                ></input>
                <button
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.2rem",
                        fontWeight: 500,
                        boxShadow: desabilitarBotaoAvancarData ? "inset 0 0 16px 1px #1a2f2a" : "0 0 10px 1px #1a2f2a",
                        color: "#fff",
                        width: 40,
                        height: 40,
                        borderRadius: "100%",
                        backgroundColor: "#1e3e49",
                        border: "none",
                        marginInline: 20,
                    }}
                    onFocus={(e) => {
                        e.target.style.outline = "none";
                    }}
                    onClick={() => {
                        voltarAvancarDataGrafico(1);
                    }}
                    disabled={desabilitarBotaoAvancarData}
                >
                    {">"}
                </button>
            </Grid>
            <Grid
                cols="12 4 4 4"
                style={{
                    display: "flex",
                    minWidth: "fit-content",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Select
                    style={{ backgroundColor: "#1e3e49", color: "#FFF", margin: 0 }}
                    name="intervalo_tempo"
                    options={[
                        { id: 5, valor: "5 minutos" },
                        { id: 10, valor: "10 minutos" },
                        { id: 15, valor: "15 minutos" },
                        { id: 30, valor: "30 minutos" },
                        { id: 60, valor: "60 minutos" },
                    ]}
                    label="Intervalo de Tempo"
                    cols="12 12 12 12"
                    isSearchable={false}
                    isClearable={false}
                    value={intervaloTempo}
                    onChange={(value) => {
                        setIntervaloTempo(value);
                    }}
                />
            </Grid>
            <div
                id="graficoTodasIntegracoesAgrupadasPorHora"
                style={{
                    width: "100%",
                    backgroundColor: "#1e3e49",
                    height: "175px",
                    borderRadius: "0 0 8px 8px",
                    overflow: "hidden",
                }}
            ></div>
        </Row>
    );
}
