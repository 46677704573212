import React, { useEffect, useState } from "react";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { montarGraficoHorizontalBar } from "../common/graficos/montarGraficoHorizontalBar";
import Select from "../common/form/select";

export function GeracaoLeituraInversores({ item, voltar }) {
    const { resumo_equipamento } = item;

    const [lista, setLista] = useState([]);
    const [grafico, setGrafico] = useState(null);
    const [periodo, setPeriodo] = useState("energia_dia");

    useEffect(() => {
        // Ordena a lista com base no período selecionado
        const listaOrdenada = [...resumo_equipamento].sort((a, b) => b[periodo] - a[periodo]);
        setLista(listaOrdenada); // Atualiza a lista no estado
    }, [resumo_equipamento, periodo]); // Atualiza quando resumo_equipamento ou periodo mudar

    useEffect(() => {
        const listaGrafico = lista.map((item) => ({
            identificador: `${item.nome_inversor || ""}${item.numero_serie_inversor ? `\n${item.numero_serie_inversor}\n` : ""}`,
            energia_dia: item.energia_dia,
            energia_mes: item.energia_mes,
            energia_ano: item.energia_ano,
        }));
        const grafcoMontado = montarGraficoHorizontalBar({
            component: grafico,
            chartDivId: "graficoEnergiaPorInversor",
            category: "identificador",
            value: periodo,
            bullet: periodo,
            bulletPosition: "auto",
            bulletColor: "#fff",
            lista: listaGrafico.reverse(), //grafico de barras renderiza na ordem contrária
            titulo: "",
            prefixo: "",
            sufixo: "kWh",
            wrapTextLabel: true,
            maxWidthLabel: 100,
        });
        setGrafico(grafcoMontado);
    }, [lista, periodo]);

    return (
        <ContentCard>
            <ContentCardBody>
                <Row alignRight>
                    <Grid
                        cols="12 6 4"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "15px",
                        }}
                    >
                        <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={voltar} />
                    </Grid>
                </Row>
                {lista.length > 1 ? (
                    <Grid
                        cols="12 12 12 12"
                        style={{
                            marginBottom: 20,
                            minHeight: 300,
                            overflow: "hidden",
                            // overflowY: "scroll",
                            justifyContent: "center",
                            padding: "20px 30px",
                            backgroundColor: "#1e3e49",
                            borderRadius: 10,
                        }}
                    >
                        <Row>
                            <Grid cols="12 4 4 4">
                                <Row alignCenter style={{ color: "#FFF", alignItems: "center", height: 70 }}>
                                    <h6>Potência Instantânea por Inversor</h6>
                                </Row>
                                {lista.map((inversor, i) => {
                                    const potenciaInstantanea = parseFloat(inversor.potencia_instantanea) || 0;
                                    const percentualProducao = parseFloat(inversor.potencia_instalada) ? Math.round((potenciaInstantanea * 100) / parseFloat(inversor.potencia_instalada)) : "?";
                                    return (
                                        <div
                                            key={i}
                                            style={{ display: "flex", justifyContent: "space-between", padding: 10, marginBlock: 10, alignItems: "center", borderRadius: 15, color: "#FFF", border: "2px solid #35e3d8" }}
                                        >
                                            <div>
                                                <div>{inversor.nome_inversor}</div>
                                                <div>{inversor.numero_serie_inversor}</div>
                                            </div>
                                            <div style={{ width: 85, height: 85, padding: "5px", fontSize: "1rem", textAlign: "center", color: "#FFF" }}>
                                                <CircularProgressbarWithChildren
                                                    styles={buildStyles({
                                                        pathColor: percentualProducao === "?" ? "#DDD" : percentualProducao <= 50 ? "#FF3838" : percentualProducao >= 85 ? "#4AE30E" : "#FFE53C",
                                                    })}
                                                    value={percentualProducao || 0}
                                                >
                                                    <div style={{ zIndex: 0 }}>
                                                        <p
                                                            style={{
                                                                margin: "11px 0 0 0",
                                                                fontSize: "0.88rem",
                                                            }}
                                                        >{`${potenciaInstantanea.toFixed(1).replace(".", ",")} kW`}</p>
                                                        <p style={{ margin: "-3px 0 0 0", fontSize: "0.7rem" }}>{`${percentualProducao} %`}</p>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Grid>
                            <Grid cols="12 8 8 8" style={{ display: "flex", flexDirection: "column" }}>
                                <Row alignCenter style={{ color: "#FFF", alignItems: "center" }}>
                                    <h6>Energia Gerada por Inversor no Período:</h6>
                                    <Select
                                        style={{ backgroundColor: "#1e3e49", color: "#FFF", marginTop: -10 }}
                                        name="periodo"
                                        options={[
                                            { id: "energia_dia", valor: "Dia" },
                                            { id: "energia_mes", valor: "Mês" },
                                            { id: "energia_ano", valor: "Ano" },
                                        ]}
                                        label=""
                                        cols="12 2 2 2"
                                        value={periodo}
                                        isSearchable={false}
                                        isClearable={false}
                                        onChange={(value) => {
                                            setPeriodo(value);
                                        }}
                                    />
                                </Row>
                                <div id="graficoEnergiaPorInversor" style={{ minHeight: 300, width: "100%", height: "90%", backgroundColor: "#082e3e", borderRadius: 10 }}></div>
                            </Grid>
                        </Row>
                    </Grid>
                ) : null}
                <Row alignCenter>
                    <h4 style={{ marginBottom: 20 }}>Lista de Inversores</h4>
                </Row>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {lista.map((inversor, i) => {
                        return (
                            <Grid cols={lista.length < 2 ? "12 12 12 12" : lista.length === 2 ? "12 6 6 6" : "12 6 4 4"} key={i}>
                                <ContentCard
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        minHeight: "fit-content",
                                        marginBottom: 20,
                                        backgroundColor: "#1e3e49",
                                        color: "#FFF",
                                        borderRadius: 15,
                                    }}
                                >
                                    <ContentCardBody padding={"15px 10px 0"}>
                                        <div>
                                            <strong>Tipo: </strong>
                                            {inversor.tipo}
                                        </div>

                                        <div>
                                            <strong>Última Atualização: </strong>
                                            {inversor.data_hora ? new Date(inversor.data_hora).toLocaleString().replace(",", "") : " - "}
                                        </div>
                                        <div>
                                            <strong>ID Sistema Fotovoltaico: </strong>
                                            {inversor.id_sistema_fotovoltaico || " - "}
                                        </div>
                                        <div>
                                            <strong>Nome do Sistema Fotovoltaico: </strong>
                                            {inversor.nome_sistema_fotovoltaico || " - "}
                                        </div>

                                        <div>
                                            <strong>ID do Inversor: </strong>
                                            {inversor.id_inversor || " - "}
                                        </div>

                                        <div>
                                            <strong>Nome do Inversor: </strong>
                                            {inversor.nome_inversor || " - "}
                                        </div>

                                        <div>
                                            <strong>Modelo do Inversor: </strong>
                                            {inversor.modelo_inversor}
                                        </div>
                                        <div>
                                            <strong>Número de Série: </strong>
                                            {inversor.numero_serie_inversor}
                                        </div>

                                        <div>
                                            <strong>Status de Conexão do Inversor: </strong>
                                            {inversor.status ? "Conectado" : "Desconectado"}
                                        </div>
                                        <div>
                                            <strong>Status de Geração do Inversor: </strong>
                                            {inversor.status && parseFloat(inversor.potencia_instantanea || 0) > 0 ? "Está gerando energia" : "Não está gerando energia"}
                                        </div>
                                        <div>
                                            <strong>Energia Dia: </strong>
                                            {!isNaN(parseFloat(inversor.energia_dia)) ? parseFloat(inversor.energia_dia).toFixed(1).replace(".", ",") + " kW" : " - "}
                                        </div>
                                        <div>
                                            <strong>Energia Mês: </strong>
                                            {!isNaN(parseFloat(inversor.energia_mes)) ? parseFloat(inversor.energia_mes).toFixed(1).replace(".", ",") + " kW" : " - "}
                                        </div>
                                        <div>
                                            <strong>Energia Ano: </strong>
                                            {!isNaN(parseFloat(inversor.energia_ano)) ? parseFloat(inversor.energia_ano).toFixed(1).replace(".", ",") + " kW" : " - "}
                                        </div>
                                        <div>
                                            <strong>Potência Instalada: </strong>
                                            {!isNaN(parseFloat(inversor.potencia_instalada)) ? parseFloat(inversor.potencia_instalada).toFixed(2).replace(".", ",") + " kW" : " - "}
                                        </div>
                                        <div>
                                            <strong>Potência Instantânea: </strong>
                                            {!isNaN(parseFloat(inversor.potencia_instantanea))
                                                ? parseFloat(inversor.status && parseFloat(inversor.potencia_instantanea) > 0 ? inversor.potencia_instantanea : 0)
                                                      .toFixed(1)
                                                      .replace(".", ",") + " kW"
                                                : " - "}
                                        </div>
                                    </ContentCardBody>
                                </ContentCard>
                            </Grid>
                        );
                    })}
                </div>
            </ContentCardBody>
        </ContentCard>
    );
}
