import "./auth.css";
import React, { Component } from "react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, setFinalizarAnimacaoLogo, initForm } from "./authActions";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import imagemLogo from "../assets/images/login_gif_extended.gif";
import gorroNatal from "../assets/images/christmas hat.gif";
import fogosArtificio from "../assets/images/fireworks.gif";
import felizAnoNovo from "../assets/images/felizAnoNovo.png";
import brinde from "../assets/images/brinde.png";
import decoracaoNatal from "../assets/images/christmas decoration.png";
import imagemLogoImagem from "../assets/images/login_imagem.png";
import imagemLogoEficience from "../assets/images/by_eficience.png";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import sleep from "../utils/sleep";

class Auth extends Component {
    state = {
        imagemLogo: { src: null },
        classeAnimacaoForm: "",
        loginMode: true,
        verSenha: false,
    };

    componentDidMount() {
        this.mounted = true;

        this.executarAnimacaoLogo();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getStyles() {
        return makeStyles((theme) => ({
            paper: {
                marginTop: theme.spacing(8),
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            avatar: {
                margin: theme.spacing(1),
                backgroundColor: theme.palette.secondary.main,
            },
            form: {
                width: "100%",
                marginTop: theme.spacing(1),
            },
            submit: {
                margin: theme.spacing(3, 0, 2),
            },
        }));
    }

    getTheme() {
        return createMuiTheme({
            palette: {
                primary: {
                    // light: '#fff',
                    main: "#fff",
                    // dark: '#fff',
                    contrastText: "#b80000",
                },
                secondary: {
                    // light: '#fff',
                    main: "#fff",
                    // dark: '#fff',
                    // contrastText: '#fff',
                },
                // Usado por `getContrastText()` para maximizar o contraste entre
                // o plano de fundo e o texto.
                contrastThreshold: 3,
                // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
                // dois índices dentro de sua paleta tonal.
                // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
                tonalOffset: 0.2,
            },
        });
    }
    async executarAnimacaoLogo() {
        await sleep(800);
        if (this.mounted) this.setState({ ...this.state, imagemLogo: { src: imagemLogo } });
        await sleep(1010);
        if (this.mounted) this.setState({ ...this.state, imagemLogo: { src: imagemLogoImagem } });
        await sleep(900);
        if (this.mounted) this.setState({ ...this.state, classeAnimacaoForm: " animacao-form" });
    }

    render() {
        const dataAtual = new Date();
        const isNatal = dataAtual.getMonth() == 11 && dataAtual.getDate() < 26;
        const isAnoNovo = (dataAtual.getMonth() == 11 && dataAtual.getDate() >= 26) || (dataAtual.getMonth() == 0 && dataAtual.getDate() <= 10);

        const classes = this.getStyles();
        return (
            <ThemeProvider theme={this.getTheme()}>
                <div style={{ backgroundColor: "#b80000", height: "100%" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "90vh",
                            backgroundSize: "cover",
                        }}
                    >
                        {isNatal ? (
                            <div style={{ width: "100vw", height: 200, overflow: "hidden", position: "fixed", top: 0, zIndex: 1 }}>
                                <img
                                    src={decoracaoNatal}
                                    style={{
                                        maxHeight: 200,
                                        marginTop: -20,
                                    }}
                                    alt="Feliz Natal"
                                />
                            </div>
                        ) : null}

                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <div
                                className={classes.paper}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "end",
                                    alignItems: "center",
                                    marginTop: -60,
                                }}
                            >
                                {this.state.imagemLogo.src ? (
                                    <div style={{ position: "relative", display: "flex", justifyContent: "center", maxWidth: 280 }}>
                                        {isNatal ? (
                                            <img
                                                src={gorroNatal}
                                                style={{
                                                    maxWidth: 90,
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 20,
                                                }}
                                                alt="Feliz Natal!"
                                            />
                                        ) : null}
                                        {isAnoNovo ? (
                                            <>
                                                <img
                                                    src={fogosArtificio}
                                                    style={{
                                                        maxWidth: 120,
                                                        filter: "brightness(2)",
                                                        position: "absolute",
                                                        top: -20,
                                                        left: -20,
                                                    }}
                                                    alt="Boas Festas!"
                                                />
                                                <img
                                                    src={felizAnoNovo}
                                                    style={{
                                                        maxWidth: 120,
                                                        filter: "brightness(3.4)",
                                                        position: "absolute",
                                                        top: -23,
                                                        right: -20, // Corrigido "rigth" para "right"
                                                        zIndex: 1,
                                                        transform: "scale(1.2) rotate(10deg)", // Substitui scale e rotate
                                                    }}
                                                    alt="Boas Festas!"
                                                />
                                                <img
                                                    src={brinde}
                                                    style={{
                                                        maxWidth: 120,
                                                        filter: "contrast(1.2)",
                                                        position: "absolute",
                                                        top: 54,
                                                        right: -45,
                                                        transform: "scale(0.42) rotate(-7deg)", // Adiciona a escala
                                                    }}
                                                    alt="Boas Festas!"
                                                />
                                            </>
                                        ) : null}
                                        <img
                                            src={this.state.imagemLogo.src}
                                            style={{
                                                maxWidth: 280,
                                                marginLeft: 65,
                                                marginRight: 65,
                                                marginBottom: 20,
                                            }}
                                            alt="Gradios"
                                        />
                                    </div>
                                ) : (
                                    <img
                                        src={imagemLogo}
                                        style={{
                                            height: "100%",
                                            maxWidth: 280,
                                            marginLeft: 65,
                                            marginRight: 65,
                                            marginBottom: 20,
                                            visibility: "hidden",
                                        }}
                                        alt="Gradios"
                                    />
                                )}

                                <form
                                    className={classes.form + this.state.classeAnimacaoForm}
                                    noValidate
                                    style={{ opacity: this.state.classeAnimacaoForm ? "" : "0%" }}
                                    onSubmit={this.props.handleSubmit((v) => {
                                        this.props.login(v);
                                    })}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        color="primary"
                                        id="login"
                                        label="Usuário"
                                        name="login"
                                        autoFocus
                                        focused
                                        inputProps={{
                                            style: { color: "#fff", border: "#fff" },
                                        }}
                                        InputLabelProps={{
                                            style: { color: "#fff", backgroundColor: "#b80000" },
                                        }}
                                        onChange={(e) => {
                                            this.props.initForm({
                                                ...this.props.formularioValues,
                                                login: e.target.value,
                                            });
                                        }}
                                    />

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        color="primary"
                                        name="senha"
                                        label="Senha"
                                        type={this.state.verSenha ? "text" : "password"}
                                        id="senha"
                                        password="true"
                                        focused
                                        InputProps={{
                                            style: { color: "#fff", border: "#fff" },
                                            endAdornment: (
                                                <InputAdornment position="end" style={{ cursor: "pointer" }}>
                                                    {this.state.verSenha ? (
                                                        <VisibilityOff
                                                            onClick={() =>
                                                                this.setState({
                                                                    ...this.state,
                                                                    verSenha: !this.state.verSenha,
                                                                })
                                                            }
                                                        />
                                                    ) : (
                                                        <Visibility
                                                            onClick={() =>
                                                                this.setState({
                                                                    ...this.state,
                                                                    verSenha: !this.state.verSenha,
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            style: { color: "#fff", backgroundColor: "#b80000" },
                                        }}
                                        onChange={(e) => {
                                            this.props.initForm({
                                                ...this.props.formularioValues,
                                                senha: e.target.value,
                                            });
                                        }}
                                    />

                                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} style={{ marginTop: 16 }}>
                                        Entrar
                                    </Button>
                                </form>
                            </div>
                            <Snackbar open={this.props.message.open}>
                                <MuiAlert severity={this.props.message.type} variant="filled">
                                    {this.props.message.text}
                                </MuiAlert>
                            </Snackbar>
                        </Container>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: -60,
                        }}
                    >
                        <img
                            alt="Eficience"
                            src={imagemLogoEficience}
                            style={{
                                width: "20%",
                                maxWidth: 100,
                                // opacity: this.state.carregamento,
                            }}
                        />
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

Auth = reduxForm({ form: "authForm" })(Auth);
const mapStateToProps = (state) => ({
    message: state.auth.message,
    formularioValues: getFormValues("authForm")(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ login, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
