import React, { Component } from "react";
import MenuItem from "./menuItem";
import MenuTree from "./menuTree";
import { withRouter } from "react-router-dom";
import { OfflineBoltSharp as OfflineBoltIcon } from "@material-ui/icons";

class Menu extends Component {
	render() {
        let selecionado = "";
        if (this.props.location && this.props.location.pathname) {
            selecionado = this.props.location.pathname;
        }

        const { sessao } = this.props;

        return (
            <nav className="mt-2">
                {!sessao ? (
                    <ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
                        <MenuItem path="/" label="Tela Inicial" icon="fas fa-tachometer-alt" active={selecionado === "/"} permitido={true} />
                    </ul>
                ) : (
                    <ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
                        <MenuItem path="/" label="Tela Inicial" icon="fas fa-tachometer-alt" active={selecionado === "/"} permitido={true} />
                        <MenuItem
                            path="usuario"
                            label="Usuário"
                            icon="fas fa-users"
                            active={selecionado === "/usuario"}
                            permitido={sessao.acesso_usuario_visualizar || sessao.acesso_usuario_adicionar || sessao.acesso_usuario_editar || sessao.acesso_usuario_excluir}
                            onClick={() => window.location.reload()}
                        />
                        <MenuTree path="#" icon="fas fa-list" label="Cadastros">
                            <MenuItem
                                path="cliente"
                                label="Cliente"
                                icon="fas fa-user-circle"
                                submenu={true}
                                active={selecionado === "/cliente"}
                                permitido={
                                    !sessao.acesso_cliente &&
                                    (sessao.acesso_cadastro_cliente_visualizar || sessao.acesso_cadastro_cliente_adicionar || sessao.acesso_cadastro_cliente_editar || sessao.acesso_cadastro_cliente_excluir)
                                }
                            />
                             <MenuItem
                                path="concessionaria"
                                label="Concessionária"
                                icon="fas fa-city"
                                submenu={true}
                                active={selecionado === "/concessionaria"}
                                permitido={
                                    !sessao.acesso_cliente &&
                                    (sessao.acesso_concessionaria_visualizar || sessao.acesso_concessionaria_adicionar || sessao.acesso_concessionaria_editar || sessao.acesso_concessionaria_excluir)
                                }
                            />
                            <MenuItem
                                path="empenho"
                                label="Empenho (prefeituras)"
                                icon="fas fa-money-check-alt"
                                submenu={true}
                                active={selecionado === "/empenho"}
                                permitido={
                                    !sessao.acesso_cliente &&
                                    !sessao.usuario_implantacao &&
                                    (sessao.acesso_empenho_visualizar || sessao.acesso_empenho_adicionar || sessao.acesso_empenho_editar || sessao.acesso_empenho_excluir)
                                }
                            />

                            <MenuItem
                                path="documento"
                                label="Anexar Documentos"
                                icon="fas fa-paste"
                                submenu={true}
                                active={selecionado === "/documento"}
                                permitido={sessao.acesso_documento_visualizar || sessao.acesso_documento_editar}
                            />
                            <MenuItem
                                path="demandaContrato"
                                label="Contrato de Demanda"
                                icon="fas fa-file-contract"
                                submenu={true}
                                active={selecionado === "/demandaContrato"}
                                permitido={
                                    !sessao.acesso_cliente &&
                                    (sessao.acesso_cadastro_demanda_visualizar || sessao.acesso_cadastro_demanda_adicionar || sessao.acesso_cadastro_demanda_editar || sessao.acesso_cadastro_demanda_excluir)
                                }
                            />
                            <MenuItem
                                path="geracao"
                                label="Micro e Mini Gerador"
                                icon="fas fa-solar-panel"
                                submenu={true}
                                active={selecionado === "/geracao"}
                                permitido={
                                    !sessao.acesso_cliente &&
                                    (sessao.acesso_cadastro_geracao_visualizar || sessao.acesso_cadastro_geracao_adicionar || sessao.acesso_cadastro_geracao_editar || sessao.acesso_cadastro_geracao_excluir)
                                }
                            />
                            <MenuItem
                                path="geracaoIntegracao"
                                label="Integração Geração"
                                icon="fas fa-sun"
                                submenu={true}
                                active={selecionado === "/geracaoIntegracao"}
                                permitido={!sessao.acesso_cliente && (sessao.acesso_solar_visualizar || sessao.acesso_solar_editar)}
                            />
                            <MenuItem
                                path="consumoIntegracao"
                                label="Integração Consumo"
                                icon="fas fa-lightbulb"
                                submenu={true}
                                active={selecionado === "/consumoIntegracao"}
                                permitido={!sessao.acesso_cliente && (sessao.acesso_monitoramento_consumo_visualizar || sessao.acesso_monitoramento_consumo_editar)}
                            />
                            <MenuItem
                                path="rede"
                                label="Rede Elétrica"
                                icon="fas fa-network-wired"
                                submenu={true}
                                active={selecionado === "/rede"}
                                permitido={!sessao.acesso_cliente && (sessao.acesso_redes_visualizar || sessao.acesso_redes_editar)}
                            />
                            <MenuItem
                                path="tributacao"
                                title="(Cadastro de %ICMS por estado e por atividade)"
                                label="Tributação"
                                icon="fas fa-percentage"
                                submenu={true}
                                marginRight={7}
                                marginLeft={23}
                                active={selecionado === "/tributacao"}
                                permitido={!sessao.acesso_cliente && (sessao.acesso_tributacao_visualizar || sessao.acesso_tributacao_adicionar || sessao.acesso_tributacao_editar || sessao.acesso_tributacao_excluir)}
                            />
                            {/* </ul>
						</li> */}
                        </MenuTree>
                        {sessao.acesso_cliente ? null : (
                            <MenuTree path="#" icon="fas fa-user-lock" label="ADM (Eficience)">
                                <MenuItem
                                    path="fatura"
                                    title="(Gestão de Importação)"
                                    label="Faturas ADM"
                                    muiIcon={<OfflineBoltIcon fontSize="inherit" />}
                                    submenu={true}
                                    marginRight={7}
                                    marginLeft={23}
                                    active={selecionado === "/fatura"}
                                    permitido={!sessao.acesso_cliente && (sessao.acesso_fatura_visualizar || sessao.acesso_fatura_editar || sessao.acesso_fatura_editar_fechado)}
                                />
                                <MenuItem
                                    path="gestaoTarefa"
                                    title="(Gestão de Tarefas)"
                                    label="Gestão de Tarefas"
                                    icon="fas fa-tasks"
                                    submenu={true}
                                    active={selecionado === "/gestaoTarefa"}
                                    permitido={
                                        !sessao.acesso_cliente &&
                                        (sessao.acesso_gestao_processo_visualizar || sessao.acesso_gestao_processo_adicionar || sessao.acesso_gestao_processo_editar || sessao.acesso_gestao_processo_excluir)
                                    }
                                />
                                <MenuItem
                                    path="documentoAdm"
                                    label="Documentos Eficience"
                                    icon="fas fa-paste"
                                    submenu={true}
                                    active={selecionado === "/documentoAdm"}
                                    permitido={!sessao.acesso_cliente && (sessao.acesso_documento_adm_visualizar || sessao.acesso_documento_adm_editar)}
                                />
                            </MenuTree>
                        )}

                        <MenuItem
                            path="faturaVisualizacao"
                            title="(Visualizar e/ou Baixar)"
                            label="Faturas"
                            icon="fas fa-bolt"
                            marginRight={3}
                            marginLeft={12}
                            active={selecionado === "/faturaVisualizacao"}
                            permitido={sessao.acesso_fatura_visualizar || sessao.acesso_fatura_editar || sessao.acesso_fatura_editar_fechado}
                        />
                        <MenuTree path="#" icon="fas fa-search-dollar" label="Verificador de Faturas" title="Identifica e ilustra custos indesejáveis que podem estar presentes em faturas de energia e água">
                            <MenuItem
                                title="Identifica e ilustra a existência de multa nas faturas de energia elétrica"
                                path="faturaEngenharia"
                                label="Identificador de Multas (Energia)"
                                icon="fas fa-file-invoice-dollar"
                                submenu={true}
                                marginLeft={12}
                                active={selecionado === "/faturaEngenharia"}
                                permitido={sessao.acesso_multa_visualizar || sessao.acesso_multa_editar || sessao.acesso_multa_editar_fechado}
                            />
                            <MenuItem
                                title="Identifica e ilustra a existência de erros de cobrança de ICMS em faturas de energia elétrica conforme cadastro"
                                path="verificadorIcmsEnergia"
                                label="Verificador de ICMS e Subsídio de Irrigantes (Energia)"
                                icon="fas fa-hand-holding-usd"
                                submenu={true}
                                marginLeft={12}
                                active={selecionado === "/verificadorIcmsEnergia"}
                                permitido={sessao.acesso_icms_energia_visualizar}
                            />
                            <MenuItem
                                title="Identifica e ilustra faturas de água com chance de possuir vazamentos e consumos muito acima da média esperada"
                                path="verificadorConsumoAgua"
                                label="Verificador de consumo (Água)"
                                icon="fas fa-faucet"
                                submenu={true}
                                marginLeft={12}
                                active={selecionado === "/verificadorConsumoAgua"}
                                permitido={sessao.acesso_consumo_agua_visualizar}
                            />
                        </MenuTree>
                        <MenuItem path="faturaRelatorio" label="Relatório" icon="fas fa-file-pdf" marginLeft={15} active={selecionado === "/faturaRelatorio"} permitido={sessao.acesso_relatorio} />
                        <MenuItem path="analise" label="Análise" icon="fas fa-chart-line" marginLeft={15} active={selecionado === "/analise"} permitido={sessao.acesso_analise} />
                        <MenuItem
                            path="/redesEletricas"
                            label="Redes Elétricas"
                            icon="fas fa-network-wired"
                            marginRight={7}
                            marginLeft={12}
                            active={selecionado === "/redesEletricas"}
                            permitido={sessao.acesso_redes_visualizar}
                        />
                        {/* <MenuItem
						path='/planejamentoFinanceiro'
						label='Planejamento Financeiro'
						icon='fas fa-dollar-sign'
						active={selecionado === '/planejamentoFinanceiro'}
						permitido={false}
						/> */}
                        <MenuItem
                            path="documentoCliente"
                            label="Documentos do Cliente"
                            icon="fas fa-paste"
                            marginLeft={15}
                            active={selecionado === "/documentoCliente"}
                            permitido={sessao.acesso_documento_visualizar || sessao.acesso_documento_editar}
                        />
                        <MenuItem
                            path="geracaoLeitura"
                            label="Monitoramento Solar"
                            icon="fas fa-sun"
                            marginLeft={14}
                            active={selecionado === "/geracaoLeitura"}
                            permitido={sessao.acesso_solar_visualizar || sessao.acesso_solar_editar}
                        />
                        <MenuItem
                            path="monitoramentoConsumo"
                            label="Monitoramento de Consumo"
                            icon="fas fa-lightbulb"
                            marginLeft={14}
                            active={selecionado === "/monitoramentoConsumo"}
                            permitido={sessao.acesso_monitoramento_consumo_visualizar || sessao.acesso_monitoramento_consumo_editar}
                        />
                    </ul>
                )}
            </nav>
        );
    }
}

export default withRouter(Menu);
