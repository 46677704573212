import axios from "axios";
import consts from "../consts";
import { initialize } from "redux-form";
import { setErroAPI, setSuccess } from "../common/toast/toast";

export function setModoTela(modo, registro = {}) {
    return {
        type: "CONCESSIONARIA_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setAguardando(aguardando) {
    return {
        type: "CONCESSIONARIA_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function initForm(registro = {}) {
    return [initialize("concessionariaForm", registro)];
}

export function salvar(registro) {
    const registroFormatado = {...registro};
    registroFormatado.cnpj = String(registroFormatado.cnpj).replace(/\D/g, ''); // Remove tudo que não é número
    return async (dispatch, getState) => {
        try {
			dispatch(setAguardando(true));
            if (!registro.id) {
                await axios.post(`${consts.API_URL}/concessionaria`, registroFormatado);
            } else {
                await axios.put(`${consts.API_URL}/concessionaria`, registroFormatado);
            }
            setSuccess("Operação Realizada com sucesso.");
            dispatch(getLista());
            dispatch(setModoTela("lista"));
        } catch (erro) {
            setErroAPI(erro);
            dispatch(
                initForm({
                    ...registro,
                })
            );
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function excluir(registro) {
    return async (dispatch, getState) => {
        try {
            if (window.confirm("Deseja realmente excluir?")) {
                dispatch(setAguardando(true));
                await axios.delete(`${consts.API_URL}/concessionaria?id=${registro.id}`);
            }
            setSuccess("Operação Realizada com sucesso.");
            dispatch(getLista());
            dispatch(setModoTela("lista"));
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/concessionaria`);
            dispatch({
                type: "CONCESSIONARIA_LISTADO",
                payload: resp,
            });
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getListaEstado() {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/estado/listarSelect`);
            dispatch({
                type: "CONCESSIONARIA_UF_SELECT_LISTADO",
                payload: resp,
            });
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

