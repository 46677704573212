const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    lista: [],
    listaGeracaoIntegracaoTipo: [],
    listaCliente: [],
    listaUnidadeConsumidora: [],
    filtro: {
        id_cliente: null,
        id_unidade_consumidora: null,
    },
};

    // id|nome
    // --+---------------------
    //  1|BYD (ENERGY CLOUD)
    //  2|EMA APP (Renovigi)
    //  3|FRONIUS
    //  4|FUSION SOLAR (Huawei)
    //  5|GROWATT
    //  6|ISOLARCLOUD (Sungrow)
    //  7|RENOCLIENT (Renovigi)
    //  8|SICESSOLAR
    //  9|SOLAREDGE
    // 10|SOLARMAN
    // 11|SOLARVIEW
    // 12|SOLIS
    // 13|WEG

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GERACAO_INTEGRACAO_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "GERACAO_INTEGRACAO_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "GERACAO_INTEGRACAO_FILTRO":
            return { ...state, filtro: action.payload };

        case "GERACAO_INTEGRACAO_LISTADO":
            return {
                ...state,
                lista: action.payload.data.map((item) => ({
                    ...item,
                    id_sistema_fotovoltaico:
                        item.id_geracao_integracao_tipo == 3
                            ? item.id_sistema_fotovoltaico && "https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=" + item.id_sistema_fotovoltaico
                            : item.id_geracao_integracao_tipo == 12
                            ? item.id_sistema_fotovoltaico && "https://www.soliscloud.com/#/station/stationDetails/generalSituation/" + item.id_sistema_fotovoltaico
                            : item.id_geracao_integracao_tipo == 11
                            ? item.id_sistema_fotovoltaico && "https://my.solarview.com.br/monitoramento?und=" + item.id_sistema_fotovoltaico
                            : [4, 10, 13, 7, 6, 9].includes(parseInt(item.id_geracao_integracao_tipo))
                            ? item.id_sistema_fotovoltaico
                            : "",
                })),
            };

        case "GERACAO_INTEGRACAO_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "GERACAO_INTEGRACAO_TIPO_SELECT_LISTADO":
            return {
                ...state,
                listaGeracaoIntegracaoTipo: action.payload.data,
            };

        case "GERACAO_INTEGRACAO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: action.payload.data,
            };

        default:
            return state;
    }
};
