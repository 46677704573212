import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import Select from "../common/form/select";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import { setModoTela, setModoTelaUC, initForm, initUcForm, gerarAcesso, getLista, definirIdUsuarioImplantacaoTodasUCs, removerIdUsuarioImplantacaoTodasUCs } from "./clienteActions";
import DateFormat from "../common/dateFormat/DateFormat";
import RadioButtom from "../common/form/radioButtom";
import { ModalForm } from "../common/dialog/modalForm";

class ClienteForm extends Component {
    state = {
        tipoCliente: "pf",
        id_usuario_implantacao_todas_uc: null,
        exibirModalDefinirPeriodoImplantacao: false,
        exibirModalRemoverPeriodoImplantacao: false,
        filtro: { id_unidade_consumidora: null, somente_inativo: false, somente_ativo: false, somente_com_data_desligamento: false, teste: true },
    };

    componentDidMount() {
        let exibirCamposFaturamento = false;
        for (let prop in this.props.formularioValues) {
            if (prop.includes("faturamento") && !!this.props.formularioValues[prop]) {
                exibirCamposFaturamento = true;
                break;
            }
        }
        this.setState({
            tipoCliente: this.props.formularioValues.cnpj ? "pj" : "pf",
            tipoClienteFaturamento: this.props.formularioValues.faturamento_cnpj ? "pj" : "pf",
            exibirCamposFaturamento,
        });
    }

    render() {
        const readOnly = this.props.excluir || this.props.reativar || this.props.visualizar || this.props.formularioValues.bloquearEdicaoClienteUsuarioImplantacao ? "readOnly" : "";

        const existeUcEmImplantacao = !!(this.props.formularioValues.itens || []).find((uc) => uc.id_usuario_implantacao);
        const todasUcMesmoImplantador = (this.props.formularioValues.itens || []).every(
            (uc) => (uc.id_usuario_implantacao !== null && uc.id_usuario_implantacao) === this.props.formularioValues.itens[0].id_usuario_implantacao
        );
        // console.log(existeUcEmImplantacao, todasUcMesmoImplantador);
        return (
            <>
                <Form event={this.props.handleSubmit}>
                    <ContentCard>
                        <ContentCardBody>
                            <Row>
                                <Field name="nome" component={LabelAndInput} label="Nome" placeholder="Informe o nome" cols="12 12 12 6" readOnly={readOnly} maxLength={150} />
                                {this.state.tipoCliente === "pf" ? (
                                    <Field name="cpf" component={LabelAndInputMask} label="CPF" placeholder="Informe o CPF" cols="12 12 12 6" mask="999.999.999-99" readOnly={readOnly} />
                                ) : (
                                    <Field name="cnpj" component={LabelAndInputMask} label="CNPJ" placeholder="Informe o CNPJ" cols="12 12 12 6" mask="99.999.999/9999-99" readOnly={readOnly} />
                                )}
                            </Row>

                            <Row>
                                <Field name="endereco" component={LabelAndInput} label="Endereço" placeholder="Informe o endereço" cols="12 12 12 6" readOnly={readOnly} maxLength={100} />
                                <Field
                                    name="telefone_residencial"
                                    component={LabelAndInputMask}
                                    label="Telefone Residencial"
                                    placeholder="Informe o número do telefone residencial"
                                    cols="12 12 12 6"
                                    mask="(99) 9999-9999"
                                    readOnly={readOnly}
                                />
                            </Row>
                            <Row>
                                <Field
                                    name="email_contato"
                                    component={LabelAndInput}
                                    label="E-mail para acompanhamento de relacionamento"
                                    placeholder="Informe o e-mail de contato"
                                    cols="12 12 12 6"
                                    readOnly={readOnly}
                                    maxLength={300}
                                />

                                <Field
                                    name="telefone_celular"
                                    component={LabelAndInputMask}
                                    label="Telefone Celular"
                                    placeholder="Informe o número do telefone celular"
                                    cols="12 12 12 6"
                                    mask="(99) 99999-9999"
                                    readOnly={readOnly}
                                />
                            </Row>
                            <Row style={{ alignItems: "end" }}>
                                <Grid
                                    cols="12 12 12 12"
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        height: "min-content",
                                        marginBottom: 20,
                                    }}
                                >
                                    <RadioButtom
                                        nameField="Selecione o tipo de cliente"
                                        options={[
                                            {
                                                id: "pf_cliente",
                                                name: "tipo_cliente",
                                                value: "pf",
                                                conditionChecked: this.state.tipoCliente === "pf",
                                                event: (e) => {
                                                    this.setState({ tipoCliente: e.target.value });
                                                    this.props.initForm({
                                                        ...this.props.formularioValues,
                                                        cnpj: null,
                                                    });
                                                },
                                                label: "Pessoa Física",
                                            },
                                            {
                                                id: "pj_cliente",
                                                name: "tipo_cliente",
                                                value: "pj",
                                                conditionChecked: this.state.tipoCliente === "pj",
                                                event: (e) => {
                                                    this.setState({ tipoCliente: e.target.value });
                                                    this.props.initForm({
                                                        ...this.props.formularioValues,
                                                        cpf: null,
                                                    });
                                                },
                                                label: "Pessoa Jurídica",
                                            },
                                        ]}
                                        readOnly={readOnly}
                                    />
                                </Grid>
                                <Field
                                    name="visualiza_analise_periodo_safra"
                                    component={LabelAndCheckbox}
                                    label="Habilitar análise período safra"
                                    title={{
                                        desmarcado: "O cliente não é enquadrado como atividade Rural, porém está indiretamente relacionado à essa atividade",
                                        marcado: "O cliente não é enquadrado como atividade Rural, porém está indiretamente relacionado à essa atividade",
                                    }}
                                    cols="12 12 12 3"
                                    readOnly={readOnly}
                                />
                                <Field name="cliente_prefeitura" component={LabelAndCheckbox} label="É uma prefeitura" cols="12 12 12 3" readOnly={readOnly} />
                                <Field name="recebe_notificacao_fatura" component={LabelAndCheckbox} label="Receber notificação de fatura" cols="12 12 12 3" readOnly={readOnly} />
                                <Field name="controla_vencimento_fatura" component={LabelAndCheckbox} label="Controlar vencimento das faturas" cols="12 12 12 3" readOnly={readOnly} />
                            </Row>

                            {this.state.exibirCamposFaturamento ? (
                                <Row>
                                    <Grid cols="6 6 6 6">
                                        <label>Faturamento</label>
                                    </Grid>

                                    <Grid
                                        cols="6 6 6 6"
                                        style={{
                                            textAlign: "right",
                                        }}
                                    >
                                        <button
                                            type="button"
                                            style={{
                                                backgroundColor: "#ff2040",
                                                color: "#ffffff",
                                                border: "none",
                                                borderRadius: 3,
                                                paddingTop: 5,
                                                width: 30,
                                                height: 30,
                                                marginBottom: -5,
                                            }}
                                            onClick={() => {
                                                if (window.confirm("Tem certeza que deseja limpar todos os campos de faturamento e fechar o formulário?")) {
                                                    this.setState({
                                                        ...this.state,
                                                        exibirCamposFaturamento: false,
                                                    });
                                                    this.props.initForm({
                                                        ...this.props.formularioValues,
                                                        faturamento_email: null,
                                                        faturamento_nome: null,
                                                        faturamento_razao_social: null,
                                                        faturamento_nome_fantasia: null,
                                                        faturamento_cpf: null,
                                                        faturamento_cnpj: null,
                                                        faturamento_inscricao_estadual: null,
                                                        faturamento_inscricao_municipal: null,
                                                        faturamento_telefone: null,
                                                        faturamento_celular: null,
                                                        faturamento_cep: null,
                                                        faturamento_id_cidade: null,
                                                        faturamento_bairro: null,
                                                        faturamento_endereco: null,
                                                        faturamento_numero: null,
                                                        faturamento_complemento: null,
                                                    });
                                                }
                                            }}
                                        >
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </Grid>
                                </Row>
                            ) : (
                                <Row>
                                    <Grid cols="12 12 6 4" style={{ paddingLeft: 14 }}>
                                        <label>Faturamento</label>
                                        <Button
                                            text="Incluir Dados de Faturamento"
                                            type={"primary"}
                                            style={{ marginLeft: 0, marginBottom: 10 }}
                                            disabled={readOnly ? true : false}
                                            event={() => {
                                                this.setState({
                                                    ...this.state,
                                                    exibirCamposFaturamento: true,
                                                });
                                            }}
                                        />
                                    </Grid>
                                </Row>
                            )}
                            {this.state.exibirCamposFaturamento ? (
                                <Row
                                    style={{
                                        borderRadius: "0.33rem",
                                        boxShadow: "inset 0 0 2px #888",
                                        padding: 15,
                                        margin: "0 1px 15px 1px",
                                    }}
                                >
                                    <Grid cols="12 12 12 4" style={{ marginBottom: 15 }}>
                                        <RadioButtom
                                            readOnly={this.props.formularioValues.bloquearEdicaoClienteUsuarioImplantacao}
                                            justifyContent="start"
                                            nameField="Selecione o tipo de cliente (faturamento)"
                                            options={[
                                                {
                                                    id: "pf_cliente_faturamento",
                                                    name: "tipo_cliente_faturamento",
                                                    value: "pf",
                                                    conditionChecked: this.state.tipoClienteFaturamento === "pf",
                                                    event: (e) => {
                                                        this.setState({
                                                            tipoClienteFaturamento: e.target.value,
                                                        });
                                                        this.props.initForm({
                                                            ...this.props.formularioValues,
                                                            faturamento_razao_social: null,
                                                            faturamento_nome_fantasia: null,
                                                            faturamento_cnpj: null,
                                                            faturamento_inscricao_estadual: null,
                                                            faturamento_inscricao_municipal: null,
                                                        });
                                                    },
                                                    label: "Pessoa Física",
                                                },
                                                {
                                                    id: "pj_cliente_faturamento",
                                                    name: "tipo_cliente_faturamento",
                                                    value: "pj",
                                                    conditionChecked: this.state.tipoClienteFaturamento === "pj",
                                                    event: (e) => {
                                                        this.setState({
                                                            tipoClienteFaturamento: e.target.value,
                                                        });
                                                        this.props.initForm({
                                                            ...this.props.formularioValues,
                                                            faturamento_nome: null,
                                                            faturamento_cpf: null,
                                                        });
                                                    },
                                                    label: "Pessoa Jurídica",
                                                },
                                            ]}
                                        />
                                    </Grid>
                                    {this.state.tipoClienteFaturamento === "pf" ? (
                                        <>
                                            <Field name="faturamento_nome" component={LabelAndInput} label="Nome" placeholder="Informe o nome (faturamento)" cols="12 12 12 4" readOnly={readOnly} maxLength={150} />
                                            <Field name="faturamento_cpf" component={LabelAndInputMask} label="CPF" placeholder="Informe o CPF (faturamento)" cols="12 12 12 4" mask="999.999.999-99" readOnly={readOnly} />
                                        </>
                                    ) : (
                                        <>
                                            <Field
                                                name="faturamento_razao_social"
                                                component={LabelAndInput}
                                                label="Razão Social"
                                                placeholder="Informe a razão social (faturamento)"
                                                cols="12 12 12 4"
                                                readOnly={readOnly}
                                                maxLength={300}
                                            />
                                            <Field
                                                name="faturamento_nome_fantasia"
                                                component={LabelAndInput}
                                                label="Nome Fantasia"
                                                placeholder="Informe a nome fantasia (faturamento)"
                                                cols="12 12 12 4"
                                                readOnly={readOnly}
                                                maxLength={300}
                                            />
                                            <Field
                                                name="faturamento_cnpj"
                                                component={LabelAndInputMask}
                                                label="CNPJ"
                                                placeholder="Informe o CNPJ de faturamento (faturamento)"
                                                cols="12 12 12 4"
                                                mask="99.999.999/9999-99"
                                                readOnly={readOnly}
                                            />
                                            <Field
                                                name="faturamento_inscricao_estadual"
                                                component={LabelAndInput}
                                                label="Inscrição Estadual"
                                                placeholder="Informe a inscrição estadual (faturamento)"
                                                cols="12 12 12 4"
                                                readOnly={readOnly}
                                                maxLength={20}
                                            />
                                            <Field
                                                name="faturamento_inscricao_municipal"
                                                component={LabelAndInput}
                                                label="Inscrição Municipal"
                                                placeholder="Informe a inscrição municipal (faturamento)"
                                                cols="12 12 12 4"
                                                readOnly={readOnly}
                                                maxLength={20}
                                            />
                                        </>
                                    )}
                                    <Field name="faturamento_cep" component={LabelAndInputMask} label="CEP" placeholder="Informe o número do CEP (faturamento)" cols="12 12 12 4" mask="99999-999" readOnly={readOnly} />
                                    <Field
                                        component={Select}
                                        name="faturamento_id_cidade"
                                        options={this.props.listaCidade}
                                        label="Cidade"
                                        placeholder="Selecione a cidade (faturamento)"
                                        cols="12 12 12 4"
                                        readOnly={readOnly}
                                    />
                                    <Field name="faturamento_bairro" component={LabelAndInput} label="Bairro" placeholder="Informe o bairro (faturamento)" cols="12 12 12 4" readOnly={readOnly} maxLength={100} />
                                    <Field
                                        name="faturamento_email"
                                        component={LabelAndInput}
                                        label="E-mail para envio de notas"
                                        placeholder="Informe o e-mail (faturamento)"
                                        cols="12 12 12 4"
                                        readOnly={readOnly}
                                        maxLength={100}
                                    />
                                    <Field
                                        name="faturamento_telefone"
                                        component={LabelAndInputMask}
                                        label="Telefone"
                                        placeholder="Informe o número do telefone (faturamento)"
                                        cols="12 12 12 4"
                                        mask="(99) 9999-9999"
                                        readOnly={readOnly}
                                    />

                                    <Field
                                        name="faturamento_celular"
                                        component={LabelAndInputMask}
                                        label="Celular"
                                        placeholder="Informe o número do celular (faturamento)"
                                        cols="12 12 12 4"
                                        mask="(99) 99999-9999"
                                        readOnly={readOnly}
                                    />

                                    <Field name="faturamento_numero" component={LabelAndInput} label="Número" placeholder="Informe o número (faturamento)" cols="12 12 12 4" readOnly={readOnly} maxLength={5} />
                                    <Field
                                        name="faturamento_complemento"
                                        component={LabelAndInput}
                                        label="Complemento"
                                        placeholder="Informe o complemento (faturamento)"
                                        cols="12 12 12 8"
                                        readOnly={readOnly}
                                        maxLength={100}
                                    />
                                </Row>
                            ) : null}
                            {this.props.formularioValues ? (
                                <>
                                    {this.props.formularioValues.id_usuario ? <label style={{ margin: "10px 0px 20px 8px" }}>Acesso</label> : null}

                                    <Grid cols="12 12 12 12">
                                        <Row style={{ alignItems: "end" }}>
                                            {!this.props.formularioValues.id_usuario ? (
                                                <Grid cols="12 12 4 4">
                                                    <label>Acesso</label>
                                                    <Button
                                                        text="Gerar Acesso"
                                                        type={"primary"}
                                                        style={{ marginLeft: 0, marginBottom: 16 }}
                                                        disabled={readOnly ? true : false}
                                                        event={() => {
                                                            this.props.gerarAcesso(this.props.formularioValues);
                                                        }}
                                                    />
                                                </Grid>
                                            ) : (
                                                <>
                                                    <Field name="login" component={LabelAndInput} label="Login" placeholder="Informe o login" cols="12 12 4 4" readOnly="readOnly" />

                                                    <Field name="senha_cliente" component={LabelAndInput} label="Senha" placeholder="Informe o senha" cols="12 12 4 4" readOnly="readOnly" />
                                                </>
                                            )}
                                            {(this.props.formularioValues.itens || []).length ? (
                                                <>
                                                    <Select
                                                        options={this.props.formularioValues.itens.map((item) => ({
                                                            id: item.id,
                                                            valor: `${item.numero} - ${item.nome}`,
                                                        }))}
                                                        label="Buscar Unidade Consumidora"
                                                        cols="12 12 4 4"
                                                        placeholder="Selecione a UC"
                                                        value={this.state.filtro.id_unidade_consumidora}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                ...this.state,
                                                                filtro: {
                                                                    ...this.state.filtro,
                                                                    id_unidade_consumidora: value,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </>
                                            ) : null}
                                        </Row>
                                    </Grid>
                                </>
                            ) : null}

                            <br />
                            <Row alignCenter={true}>
                                {this.props.excluir ? (
                                    <>
                                        <Grid cols="12 4 3 2" style={{ paddingTop: 5 }}>
                                            <Button
                                                text="Inativar"
                                                type={"dark"}
                                                event={() => {
                                                    this.props.excluir({
                                                        id: this.props.formularioValues.id,
                                                        desligado: true,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid cols="12 4 3 2" style={{ paddingTop: 5 }}>
                                            <Button
                                                text="Excluir "
                                                event={() => {
                                                    this.props.excluir({
                                                        id: this.props.formularioValues.id,
                                                        desligado: false,
                                                    });
                                                }}
                                                type={"danger"}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid cols="12 4 3 2" style={{ paddingTop: 5 }}>
                                        {this.props.reativar ? (
                                            <Button
                                                text="Reativar"
                                                type={"success"}
                                                event={() => {
                                                    this.props.reativar({
                                                        id: this.props.formularioValues.id,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Button text="Salvar" submit type={"success"} disabled={readOnly} />
                                        )}
                                    </Grid>
                                )}

                                <Grid cols="12 4 3 2" style={{ paddingTop: 5 }}>
                                    <Button
                                        text="Voltar"
                                        type={"warning"}
                                        icon={"fa fa-chevron-left"}
                                        event={() => {
                                            this.props.getLista();
                                            this.props.setModoTela("lista");
                                        }}
                                    />
                                </Grid>
                            </Row>
                        </ContentCardBody>
                    </ContentCard>
                    <ContentCard>
                        <ContentCardBody>
                            {this.state.exibirModalDefinirPeriodoImplantacao ? (
                                <ModalForm
                                    titulo="Definir Período de Implantação para Todas as UCs"
                                    item={{ ...this.state }}
                                    handleSubmit={(state) => {
                                        this.props.definirIdUsuarioImplantacaoTodasUCs({
                                            id_cliente: this.props.formularioValues.id,
                                            id_usuario_implantacao_todas_uc: state.id_usuario_implantacao_todas_uc,
                                            callback: () => this.setState({ exibirModalDefinirPeriodoImplantacao: false }),
                                        });
                                    }}
                                    handleClose={() => this.setState({ id_usuario_implantacao_todas_uc: null, exibirModalDefinirPeriodoImplantacao: false })}
                                >
                                    <Select
                                        style={{ marginTop: 40 }}
                                        name="id_usuario_implantacao_todas_uc"
                                        options={this.props.listaUsuarioImplantacao}
                                        label="Nome do Usuário de Implantação"
                                        cols="12 12 12 12"
                                        placeholder="Selecione o usuário de implantação"
                                        readOnly={readOnly}
                                        isClearable={false}
                                    />
                                </ModalForm>
                            ) : null}

                            {this.state.exibirModalRemoverPeriodoImplantacao ? (
                                <ModalForm
                                    titulo="Remover Período de Implantação para Todas as UCs"
                                    item={{ ...this.state }}
                                    textoBotaoSubmit="Confirmar"
                                    handleSubmit={(state) => {
                                        this.props.removerIdUsuarioImplantacaoTodasUCs({
                                            id_cliente: this.props.formularioValues.id,
                                            callback: () => this.setState({ exibirModalRemoverPeriodoImplantacao: false }),
                                        });
                                    }}
                                    handleClose={() => this.setState({ exibirModalRemoverPeriodoImplantacao: false })}
                                >
                                    <p style={{ marginBlock: 40, textAlign: "center" }} className="text-danger">
                                        Deseja realmente remover o usuário de implantação de todas as unidades consumidoras?
                                    </p>
                                </ModalForm>
                            ) : null}

                            <Row style={{ padding: "10px 10px 30px 10px" }}>
                                <Grid cols="12 12 6 4">
                                    <Button
                                        title={this.props.formularioValues.id ? "Incluir uma nova unidade consumidora" : "Cadastre o cliente primeiro para poder adicionar unidades consumidoras"}
                                        text="Adicionar Nova Unidade Consumidora"
                                        type={"primary"}
                                        icon={"fa fa-plus"}
                                        disabled={
                                            this.props.excluir || //bloqueia adição no modo excluir
                                            this.props.reativar || //modo reativar n pode editar
                                            !this.props.formularioValues.id || //bloqueia adição enquanto o cliente ainda não está cadastrado
                                            (this.props.visualizar && !this.props.usuarioLogado.usuario_implantacao) || //só um implantador pode incluir uc pelo visualizar
                                            (!this.props.usuarioLogado.usuario_implantacao && !this.props.usuarioLogado.acesso_cadastro_cliente_adicionar && !this.props.usuarioLogado.acesso_cadastro_cliente_editar)
                                        }
                                        style={{ marginLeft: 0 }}
                                        event={() => {
                                            const itemForm = {
                                                ativo: true,
                                                id_cliente: this.props.formularioValues.id,
                                                id_usuario_implantacao: this.props.usuarioLogado.usuario_implantacao ? this.props.usuarioLogado.id : null,
                                            };
                                            this.props.setModoTelaUC("cadastroUC", itemForm);
                                            this.props.initUcForm(itemForm);
                                        }}
                                    />
                                </Grid>
                                <Grid cols="12 12 6 4">
                                    <Button
                                        title={"Incluir Período de Implantação (Todas UCs)"}
                                        text="Incluir Período de Implantação (Todas UCs)"
                                        type={"info"}
                                        icon={"fa fa-cog"}
                                        disabled={
                                            this.props.excluir || //bloqueia adição no modo excluir
                                            this.props.reativar || //modo reativar n pode editar
                                            !this.props.formularioValues.id || //bloqueia adição enquanto o cliente ainda não está cadastrado
                                            !(this.props.formularioValues.itens || []).length || //se não tiver ucs na lista
                                            todasUcMesmoImplantador || //se ja tiver tudo com implantação definido, desabilita
                                            (this.props.visualizar && !this.props.usuarioLogado.usuario_implantacao) || //só um implantador pode incluir uc pelo visualizar
                                            (!this.props.usuarioLogado.usuario_implantacao && !this.props.usuarioLogado.acesso_cadastro_cliente_adicionar && !this.props.usuarioLogado.acesso_cadastro_cliente_editar)
                                        }
                                        style={{ marginLeft: 0 }}
                                        event={() => this.setState({ exibirModalDefinirPeriodoImplantacao: true })}
                                    />
                                </Grid>
                                <Grid cols="12 12 6 4">
                                    <Button
                                        title={"Remover Período de Implantação (Todas UCs)"}
                                        text="Remover Período de Implantação (Todas UCs)"
                                        type={"danger"}
                                        icon={"fa fa-cog"}
                                        disabled={
                                            this.props.excluir || //bloqueia adição no modo excluir
                                            this.props.reativar || //modo reativar n pode editar
                                            !this.props.formularioValues.id || //bloqueia adição enquanto o cliente ainda não está cadastrado
                                            !(this.props.formularioValues.itens || []).length || //se não tiver ucs na lista
                                            !existeUcEmImplantacao || //se ja ta tudo sem implantação desabilita
                                            (this.props.visualizar && !this.props.usuarioLogado.usuario_implantacao) || //só um implantador pode incluir uc pelo visualizar
                                            (!this.props.usuarioLogado.usuario_implantacao && !this.props.usuarioLogado.acesso_cadastro_cliente_adicionar && !this.props.usuarioLogado.acesso_cadastro_cliente_editar)
                                        }
                                        style={{ marginLeft: 0 }}
                                        event={() => this.setState({ exibirModalRemoverPeriodoImplantacao: true })}
                                    />
                                </Grid>
                            </Row>
                            <Row style={{ alignItems: "center" }}>
                                <div
                                    style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                    onClick={() =>
                                        this.setState({
                                            ...this.state,
                                            filtro: {
                                                ...this.state.filtro,
                                                somente_ativo: !this.state.filtro.somente_ativo,
                                                somente_inativo: false,
                                                somente_com_data_desligamento: false,
                                            },
                                        })
                                    }
                                >
                                    <div
                                        style={{
                                            width: 24,
                                            height: 20,
                                            border: "2px solid #fff",
                                            backgroundColor: "#dcdcdc",
                                            boxShadow: "0px 0px 5px 2px #b1b1b1",
                                            margin: "15px 20px 15px 30px",
                                            borderRadius: 4,
                                            textAlign: "center",
                                            lineHeight: "18px",
                                        }}
                                    >
                                        {this.state.filtro.somente_ativo ? <i class="fas fa-check"></i> : null}
                                    </div>
                                    <b>UCs ativas sem data de desligamento ({(this.props.formularioValues.itens || []).filter((item) => item.ativo && !item.data_desligamento).length})</b>
                                </div>
                                <div
                                    style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                    onClick={() =>
                                        this.setState({
                                            ...this.state,
                                            filtro: {
                                                ...this.state.filtro,
                                                somente_inativo: !this.state.filtro.somente_inativo,
                                                somente_com_data_desligamento: false,
                                                somente_ativo: false,
                                            },
                                        })
                                    }
                                >
                                    <div
                                        style={{
                                            width: 24,
                                            height: 20,
                                            border: "2px solid #fff",
                                            backgroundColor: "#ff6569",
                                            boxShadow: "0px 0px 5px 2px #b1b1b1",
                                            margin: "15px 20px 15px 30px",
                                            borderRadius: 4,
                                            textAlign: "center",
                                            lineHeight: "18px",
                                        }}
                                    >
                                        {this.state.filtro.somente_inativo ? <i class="fas fa-check"></i> : null}
                                    </div>
                                    <b>UCs inativas ({(this.props.formularioValues.itens || []).filter((item) => !item.ativo).length})</b>
                                </div>
                                <div
                                    style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                    onClick={() =>
                                        this.setState({
                                            ...this.state,
                                            filtro: {
                                                ...this.state.filtro,
                                                somente_com_data_desligamento: !this.state.filtro.somente_com_data_desligamento,
                                                somente_inativo: false,
                                                somente_ativo: false,
                                            },
                                        })
                                    }
                                >
                                    <div
                                        style={{
                                            width: 24,
                                            height: 20,
                                            border: "2px solid #fff",
                                            backgroundColor: "#ffe367",
                                            boxShadow: "0px 0px 5px 2px #b1b1b1",
                                            margin: "15px 20px 15px 30px",
                                            borderRadius: 4,
                                            textAlign: "center",
                                            lineHeight: "18px",
                                        }}
                                    >
                                        {this.state.filtro.somente_com_data_desligamento ? <i class="fas fa-check"></i> : null}
                                    </div>
                                    <b>UCs ativas com data de desligamento ({(this.props.formularioValues.itens || []).filter((item) => item.ativo && item.data_desligamento).length})</b>
                                </div>
                            </Row>
                            <Table maxHeight="40vh" responsive>
                                <THead>
                                    <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#F5F5F5"}>
                                        <Th alignCenter={true} sticky>
                                            Situação
                                        </Th>
                                        <Th alignCenter={true} minWidth={110} sticky>
                                            Concessionária
                                        </Th>
                                        <Th alignCenter={true} minWidth={110} sticky>
                                            Código da UC
                                        </Th>
                                        <Th alignCenter={true} sticky>
                                            Nome
                                        </Th>
                                        <Th alignCenter={true} sticky>
                                            UF
                                        </Th>
                                        <Th alignCenter={true} sticky>
                                            Tipo Atividade
                                        </Th>
                                        <Th alignCenter={true} minWidth={80} sticky>
                                            (% ICMS)
                                        </Th>
                                        <Th alignCenter={true} sticky>
                                            Grupo Faturamento
                                        </Th>
                                        <Th sticky>Tipo Mercado</Th>
                                    </NewTr>
                                </THead>
                                <TBody>
                                    {(this.props.formularioValues.itens || [])
                                        .filter((item) => {
                                            const filtroUC = !this.state.filtro.id_unidade_consumidora || item.id == this.state.filtro.id_unidade_consumidora;
                                            const filtroAtivas = !this.state.filtro.somente_ativo || (item.ativo && !item.data_desligamento);
                                            const filtroInativas = !this.state.filtro.somente_inativo || !item.ativo;
                                            const filtroDataDesligamento = !this.state.filtro.somente_com_data_desligamento || (item.ativo && item.data_desligamento);
                                            return filtroUC && filtroInativas && filtroAtivas && filtroDataDesligamento;
                                        })
                                        .map((item) => {
                                            const itemForm = {
                                                ...item,
                                                data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento),
                                            };
                                            let backgroundColorTr = "#ffffff";
                                            let backgroundColorTd = "#f5f5f5";
                                            let situacao = "Ativa";

                                            if (!item.ativo) {
                                                backgroundColorTr = "#ff6569";
                                                backgroundColorTd = "#fbcaca";
                                                situacao = "Inativa";
                                            } else if (item.ativo && item.data_desligamento) {
                                                backgroundColorTr = "#ffe367";
                                                backgroundColorTd = "#ffe5bd";
                                                const desligada = new Date(item.data_desligamento) <= new Date();
                                                situacao = desligada ? "Desligada" : "Desligamento Agendado";
                                            }

                                            if (item.usuario_implantacao) {
                                                situacao = (
                                                    <>
                                                        <p
                                                            style={{
                                                                backgroundColor: "#257bfb",
                                                                textAlign: "center",
                                                                borderRadius: "0.3rem",
                                                                color: "#fff",
                                                                margin: "2px 10px 2px 2px",
                                                                padding: "2px 5px",
                                                            }}
                                                        >
                                                            Implantação
                                                        </p>
                                                        <span>{situacao}</span>
                                                    </>
                                                );
                                            }

                                            const concessionaria = (this.props.listaConcessionaria.find((c) => c.id == item.id_concessionaria) || {}).valor;
                                            const aliquotaIcms = (this.props.listaTributacao.find((t) => t.id == item.id_tributacao) || {}).aliquota_icms;
                                            const uf = (this.props.listaEstado.find((c) => c.id == item.id_estado) || {}).valor;
                                            const atividade = (this.props.listaAtividade.find((a) => a.id == item.id_atividade) || {}).valor;
                                            const grupoFaturamento = (this.props.listaGrupoFaturamento.find((g) => g.id == item.id_grupo_faturamento) || {}).valor;
                                            const tipoMercado = (this.props.listaTipoMercado.find((m) => m.id == item.id_tipo_mercado) || {}).valor;

                                            const botoes = [
                                                {
                                                    type: "primary",
                                                    icon: "fas fa-eye",
                                                    disabled: false, //qualquer um que acessar tela do cliente pode ver UC tmb
                                                    event: () => {
                                                        this.props.setModoTelaUC("visualizacaoUC", itemForm);
                                                        this.props.initUcForm(itemForm);
                                                    },
                                                },
                                            ];
                                            const readOnlyUc =
                                                this.props.excluir || //modo excluir n pode editar
                                                this.props.reativar || //modo reativar n pode editar
                                                (this.props.visualizar && !this.props.usuarioLogado.usuario_implantacao) || //se alguem que não seja implantador visualizar n pode editar
                                                (!this.props.usuarioLogado.acesso_cadastro_cliente_editar && !this.props.usuarioLogado.usuario_implantacao) || // se não for implantador e não puder editar o cliente então bloqueia edição de UC tmb
                                                item.bloquearEdicaoUcUsuarioImplantacao; //se usuario logado for implantação e não for o implantador da UC bloqueia

                                            if (item.ativo) {
                                                botoes.push(
                                                    {
                                                        type: "warning",
                                                        icon: "fas fa-pencil-alt",
                                                        disabled: readOnlyUc,
                                                        event: () => {
                                                            this.props.setModoTelaUC("cadastroUC", itemForm);
                                                            this.props.initUcForm(itemForm);
                                                        },
                                                    },
                                                    {
                                                        type: "danger",
                                                        icon: "fas fa-trash-alt",
                                                        disabled: readOnlyUc,
                                                        event: () => {
                                                            this.props.setModoTelaUC("exclusaoUC", itemForm);
                                                            this.props.initUcForm(itemForm);
                                                        },
                                                    }
                                                );
                                            } else {
                                                botoes.push({
                                                    type: "success",
                                                    icon: "fas fa-power-off",
                                                    disabled: readOnlyUc,
                                                    event: () => {
                                                        this.props.setModoTelaUC("reativarUC", itemForm);
                                                        this.props.initUcForm(itemForm);
                                                    },
                                                });
                                            }

                                            return (
                                                <NewTr key={item.id} backgroundColorTd={backgroundColorTd} backgroundColorTr={backgroundColorTr} botoes={botoes}>
                                                    <Td alignCenter={true}>{situacao}</Td>
                                                    <Td alignCenter={true}>{concessionaria}</Td>
                                                    <Td alignCenter={true}>{item.numero}</Td>
                                                    <Td minWidth={250}>{item.nome}</Td>
                                                    <Td alignCenter={true} minWidth={130}>
                                                        {uf}
                                                    </Td>
                                                    <Td alignCenter={true} minWidth={130}>
                                                        {atividade}
                                                    </Td>
                                                    <Td alignCenter={true}>{aliquotaIcms ? aliquotaIcms.replace(".", ",") + "%" : ""}</Td>
                                                    <Td alignCenter={true}>{grupoFaturamento}</Td>
                                                    <Td minWidth={110}>{tipoMercado}</Td>
                                                </NewTr>
                                            );
                                        })}
                                </TBody>
                            </Table>
                        </ContentCardBody>
                    </ContentCard>
                </Form>
            </>
        );
    }
}

ClienteForm = reduxForm({ form: "clienteForm", destroyOnUnmount: false })(ClienteForm);
const mapStateToProps = (state) => ({
    usuarioLogado: state.auth.usuarioLogado,
    formularioValues: getFormValues("clienteForm")(state),
    listaCidade: state.cliente.listaCidade,
    listaConcessionaria: state.cliente.listaConcessionaria,
    listaGrupoFaturamento: state.cliente.listaGrupoFaturamento,
    listaTipoMercado: state.cliente.listaTipoMercado,
    listaTributacao: state.tributacao.lista,
    listaEstado: state.tributacao.listaEstado,
    listaAtividade: state.tributacao.listaAtividade,
    listaUsuarioImplantacao: state.usuario.lista.filter((item) => item.usuario_implantacao).map((item) => ({ id: item.id, valor: item.nome })),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            setModoTelaUC,
            initForm,
            initUcForm,
            gerarAcesso,
            getLista,
            definirIdUsuarioImplantacaoTodasUCs,
            removerIdUsuarioImplantacaoTodasUCs,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ClienteForm);
