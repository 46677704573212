import React from "react";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar";

export function GeracaoLeituraModulos({ modulos, voltar }) {
    return (
        <ContentCard>
            <ContentCardBody>
                <Row alignRight>
                    <Grid
                        cols="12 6 4"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "15px",
                        }}
                    >
                        <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={voltar} />
                    </Grid>
                </Row>
                <Row alignCenter>
                    <h4 style={{ marginBottom: 20 }}>Lista de Módulos</h4>
                </Row>

                {modulos.map((item, i) => {
                    return (
                        <Row key={i}>
                            <ContentCard
                                style={{
                                    width: "100%",
                                    marginInline: 20,
                                    marginBottom: 20,
                                }}
                            >
                                <ContentCardBody padding={"15px 10px 0"}>
                                    <p> Marca/Modelo: {item.modulos_marca_modelo} </p>
                                    <p> Quantidade: {parseInt(item.modulos_quantidade) || 0} módulos </p>
                                    <p> Potência: {adicionarPontosMilhar((Number(item.modulos_potencia_total) || 0).toFixed(2))} kW </p>
                                </ContentCardBody>
                            </ContentCard>
                        </Row>
                    );
                })}
            </ContentCardBody>
        </ContentCard>
    );
}
