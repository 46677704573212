import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Button from "../common/button/button";
import Form from "./concessionariaForm";
import { setModoTela, initForm, salvar, excluir, getLista, getListaEstado } from "./concessionariaActions";
import { Loading } from "../common/loading/loading";

class Concessionaria extends Component {
    componentWillMount() {
        this.props.getListaEstado();
        this.props.getLista();
    }

    render() {
        //Sem acesso
        if (!this.props.usuarioLogado || this.props.usuarioLogado.acesso_cliente) {
            return <div />;
        }

        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                <Content>
                    {this.props.modoTela === "visualizacao" ? <Form visualizar onSubmit={() => {}} /> : null}
                    {this.props.modoTela === "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela === "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {this.props.modoTela === "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    lista() {
        return (
            <ContentCard>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="12 6 4 2">
                            <Button
                                text="Adicionar"
                                type={"success"}
                                icon={"fa fa-plus"}
                                disabled={!this.props.usuarioLogado.acesso_concessionaria_adicionar}
                                event={() => {
                                    this.props.setModoTela("cadastro", {});
                                    this.props.initForm({});
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody>
                    <Table responsive>
                        <THead>
                            <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#F5F5F5"}>
                                <Th sticky>Nome</Th>
                                <Th sticky>UF</Th>
                                <Th sticky>CNPJ</Th>
                            </NewTr>
                        </THead>
                        <TBody>
                            {this.props.lista.map((item) => {
                                const registro = { ...item};
                                return (
                                    <NewTr
                                        key={item.id}
                                        backgroundColorTr={"#FFFFFF"}
                                        backgroundColorTd={"#F5F5F5"}
                                        botoes={[
                                            {
                                                type: "primary",
                                                icon: "fas fa-eye",
                                                disabled: !this.props.usuarioLogado.acesso_concessionaria_visualizar,
                                                event: () => {
                                                    this.props.setModoTela("visualizacao", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                            {
                                                type: "warning",
                                                icon: "fas fa-pencil-alt",
                                                disabled: !this.props.usuarioLogado.acesso_concessionaria_editar,
                                                event: () => {
                                                    this.props.setModoTela("cadastro", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                            {
                                                type: "danger",
                                                icon: "fas fa-trash-alt",
                                                disabled: !this.props.usuarioLogado.acesso_concessionaria_excluir,
                                                event: () => {
                                                    this.props.setModoTela("exclusao", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                        ]}
                                    >
                                        <Td>{item.nome}</Td>
                                        <Td minWidth={"30vw"}>{item.nome_estado}</Td>
                                        <Td>{item.cnpj ? item.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : " - "}</Td>
                                    </NewTr>
                                );
                            })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.concessionaria.modoTela,
	formularioValues: getFormValues("concessionariaForm")(state),
	aguardando: state.concessionaria.aguardando,
	filtro: state.concessionaria.filtro,
	lista: state.concessionaria.lista,
	listaEstado: state.concessionaria.listaEstado,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			salvar,
			excluir,
			getLista,
			getListaEstado,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Concessionaria);
