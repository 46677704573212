import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

export function montarGraficoLineTodasIntegracoesAgrupadasPorHora({id, lista, setChart}) {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(id, am4charts.XYChart);
    const title = chart.titles.create();
    title.text = "Energia Gerada no Dia";
    title.fontWeight = "bold";
    title.fontSize = "0.9rem";
    // title.fill = am4core.color("#000");
    title.fill = am4core.color("#fff");
    title.marginBottom = 10;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = "Gráfico";
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
        {
            label: "...",
            menu: [
                {
                    type: "png",
                    label: "PNG",
                },
            ],
        },
    ];
    chart.language.locale = locale;
    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";
    chart.numberFormatter.numberFormat = "###,###,###,###.00";
    chart.background.fill = "#1e3e49";
    chart.background.opacity = 1;

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.dateFormats.setKey("minute", "HH:mm");
    // dateAxis.periodChangeDateFormats.setKey("minute", "hh:mm");
    dateAxis.baseInterval = {
        timeUnit: "minute",
        count: 5,
    };
    // dateAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo x
    dateAxis.renderer.labels.template.fill = am4core.color("#fff"); //cor do texto eixo x
    dateAxis.renderer.maxLabelPosition = 0.95;
    dateAxis.renderer.minGridDistance = 40;
    dateAxis.renderer.minLabelPosition = 0.05;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (chart.yAxes.indexOf(valueAxis) != 0) {
        valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
    }
    valueAxis.min = 0;
    // valueAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
    valueAxis.renderer.labels.template.fill = am4core.color("#fff"); //cor do texto eixo y
    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "potencia_instantanea_media_hora";
    series.dataFields.dateX = "data_hora";
    series.yAxis = valueAxis;
    series.name = "Energia gerada ao longo do dia";
    series.tooltipText = `Potência Instantânea às {dateX.formatDate("HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} kW[/]`;
    series.strokeWidth = 2;
    series.tensionX = 1;
    series.stacked = true;
    series.stroke = am4core.color("#017acd");
    series.fill = am4core.color("#017acd");
    series.fillOpacity = 0.2;

    //Adiciona cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;

    //configura a cor do tooltip x
    const dateAxisTooltip = dateAxis.tooltip;
    dateAxisTooltip.background.fill = am4core.color("#0070cc");
    dateAxisTooltip.stroke = am4core.color("#fff");
    dateAxisTooltip.background.strokeWidth = 0;
    dateAxisTooltip.background.cornerRadius = 3;
    dateAxisTooltip.background.pointerLength = 0;
    dateAxisTooltip.dy = 5;

    //configura a cor do tooltip y
    const valueAxisTooltip = valueAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#0070cc");
    valueAxisTooltip.stroke = am4core.color("#fff");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.background.pointerLength = 0;
    valueAxisTooltip.dy = 5;

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;

    chart.data = lista.map((leitura) => {
        const { potencia_instantanea_media_hora, data_hora, ...rest } = leitura;
        return { potencia_instantanea_media_hora, data_hora: new Date(data_hora) };
    });

    setChart(chart);
}